import { Controller } from 'stimulus';
import { trackEvent } from 'src/v3/gtm_tracker';
import { post, destroy } from '@rails/request.js';
import  * as Turbo from '@hotwired/turbo';


const ACTIONS = {
  add: 'Add',
  remove: 'Remove'
}

export default class extends Controller {
  static values = {
    isSaved: Boolean,
    actionPrefix: String,
    addPath: String,
    removePath: String,
    carId: Number,
  }

  static targets = [
    'icon',
    'text'
  ];

  connect(){
    this.saved = this.hasIsSavedValue && this.isSavedValue
    this.updateUI();
  }

  async toggleSave(event){
    event.preventDefault();

    let action = this.saved ? ACTIONS.remove : ACTIONS.add;

    // track gtm event
    trackEvent({
      action: this.actionPrefixValue + action,
      category: 'CarListing::SavedCars',
      label: this.data.get('label')
    });

    if(action == ACTIONS.add){
      let response = await post(this.addPathValue, { responseKind: 'json' })
      if(response.ok){
        let data = await response.json
        if(data.success){
          this.saved = true
          this.updateUI()
        }
      }else{
        let data = await response.json
        if(data.redirect_to) Turbo.visit(data.redirect_to);
      }
    }else{
      const response = await destroy(this.removePathValue, { responseKind: 'json' })
      if(response.ok){
        let data = await response.json
        if(data.success){
          this.saved = false;
          this.updateUI();
        }
      }
    }
    const new_event = new CustomEvent("car-listing--save-button.toggled", { detail: {car_id: this.carIdValue, action: action}})
    this.element.dispatchEvent(new_event)
  }

  updateUI(){
    if(this.saved){
      //change icon
      this.iconTarget.classList.remove('far')
      this.iconTarget.classList.add('fas')

      //change text
      this.textTarget.textContent = ACTIONS.remove;
    }else{
      //change icon
      this.iconTarget.classList.remove('fas')
      this.iconTarget.classList.add('far')

      //change text
      this.textTarget.textContent = ACTIONS.add;
    }
  }
}
