import { Controller } from 'stimulus';
import { trackEvent } from 'src/v3/gtm_tracker';
import { breakpoints } from 'src/v3/utils';

export default class extends Controller {
  static targets = [
    'advancedRefinements',
    'cardBody',
    'modalBody',
  ]

  connect(){
    if(window.innerWidth >= breakpoints.lg){
      this.initSidebar();
    }
    this.initAdvancedRefinementsCollapse();
  }

  initSidebar(){
    while(this.modalBodyTarget.childNodes.length){
      this.cardBodyTarget.appendChild(this.modalBodyTarget.firstChild);
    }
  }

  initAdvancedRefinementsCollapse(){
    // We are using querySelector here as an exception
    // A collapse toggle with data-target will not work because toggle
    // is using data-target to determine which div to toggle
    const btn = this.element.querySelector(`[href="#${this.advancedRefinementsTarget.id}"]`);

    this.advancedRefinementsTarget
      .addEventListener('show.bs.collapse', (e) => {
        btn.text = 'Show less';
        trackEvent({
          category: 'CarListing::RefinementsComponent',
          action: 'CollapseShow',
          label: e.target.id
        })
      });
    this.advancedRefinementsTarget
      .addEventListener('hide.bs.collapse', (e) => {
        btn.text = 'Show more';
        trackEvent({
          category: 'CarListing::RefinementsComponent',
          action: 'CollapseHide',
          label: e.target.id
        });
      });
  }
}
