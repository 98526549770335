
const panoramaListener = () => {
  var body = document.body;

  const addListener = () => {
    var panorama = document.getElementById("panorama-image");
    if (panorama) {
      panorama.addEventListener('click', ()=>{
        let url = panorama.dataset.url
        pannellum.viewer('panorama', {
          "type": "equirectangular",
          "panorama": url,
          "autoLoad": false
        });
        var myModalEl = document.getElementById('panorama-modal')
        myModalEl.addEventListener('shown.bs.modal', function (event) {
          setTimeout(
            function(){
            document.getElementsByClassName('pnlm-load-button')[0].click();
          }, 300)
        })
      });
    }
  };

  const init = () => {
    addListener();
  };

  if (body.classList.contains('car-listings') && body.classList.contains('show') ||
  body.classList.contains('brand-new--car-model') && body.classList.contains('show'))
   {
     init();
   }
}

export default panoramaListener;
