import { Controller } from 'stimulus';
import { patch } from '@rails/request.js'

export default class extends Controller {

  static targets = [ 'continueBtn', 'sort' ]

  async connect(){
    let { Sortable } = await import('sortablejs');
    new Sortable(this.sortTarget, {
      animation: 150,
      ghostClass: 'bg-300',
      onUpdate: this.updateImageOrder.bind(this)
    })
  }

  async updateImageOrder(evt){
    let text = this.continueBtnTarget.innerHTML
    this.continueBtnTarget.innerHTML = 'Loading …'
    this.continueBtnTarget.dataset.disable = 'true'
    let item = evt.item;
    let url = item.dataset.url;
    let position = 1;
    if(this.isLastElement(item)){
      position = this.prevElementPosition(item) + 1000
    }else{
      position = (this.prevElementPosition(item) + this.nextElementPosition(item))/2
    }
    const imageOrderResponse = await patch(url, {
                                            body: { image: { position: position } },
                                            contentType: 'application/json'
                                          });
    if(imageOrderResponse.ok){
      this.continueBtnTarget.innerHTML = text
      this.continueBtnTarget.dataset.disable = 'false'
      item.dataset.currentOrder = position
      console.log('Sucessfully ordered image')
    }else{
      alert('Cannot order image, please delete the image and upload again!')
      console.error(`Error occured ordering image ${url}`)
    }
  }
  continue(e) {
    if(this.continueBtnTarget.dataset.disable == 'true'){
      e.preventDefault();
    }
  }

  isLastElement(item){
    return !this.hasNextElement(item)
  }

  hasPreviousElement(item){
    return item.previousElementSibling != null
  }

  hasNextElement(item){
    return item.nextElementSibling != null
  }

  nextElementPosition(item){
    return parseFloat(item.nextElementSibling.dataset.currentOrder)
  }

  prevElementPosition(item){
    if(this.hasPreviousElement(item)){
      return parseFloat(item.previousElementSibling.dataset.currentOrder)
    }else{
      return 0
    }
  }


}
