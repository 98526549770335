import connectPagination from 'instantsearch.js/es/connectors/pagination/connectPagination';

const paginationRender = (renderOptions, isFirstRender) => {
  // Rendering logic
  const {
    pages, //number[]
    currentRefinement, //number
    isFirstPage, //boolean
    isLastPage, //boolean
    refine, //fn
    createURL, //fn
    widgetParams, //fn
  } = renderOptions;

  if(isFirstRender){
    //for first render.
  }
  const pageItem = (page) => {
    return `
      <li class="page-item ${currentRefinement === page ? 'active' : ''}">
        <a class="page-link"
          data-value="${page}"
          href="${createURL(page)}">${page + 1}</a>
      </li>`;
  }
  const previousLink = isFirstPage
    ? ''
    : `<li class="page-item">
        <a class="page-link"
           data-value="${currentRefinement - 1}"
           href="${createURL(currentRefinement - 1)}">
           &laquo;
        </a>
      </li>`;
  const nextLink = isLastPage
    ? ''
    : `<li class="page-item">
        <a class="page-link"
           data-value="${currentRefinement + 1}"
           href="${createURL(currentRefinement + 1)}">
          &raquo;
        </a>
      </li>`;

  widgetParams.container.innerHTML = `
    <nav aria-label="page navigation">
      <ul class="pagination justify-content-center">
        ${previousLink}
        ${pages.map(pageItem).join('')}
        ${nextLink}
      </ul>
    </nav>
  `;

  const clickListener = (event) => {
    if(event.target.tagName != 'A') return

    event.preventDefault();
    refine(event.target.dataset.value);
    widgetParams.scrollToNode.scrollIntoView();
  }

  widgetParams.container.addEventListener('click', clickListener)
};

export default connectPagination(paginationRender);
