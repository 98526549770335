import connectHits from 'instantsearch.js/es/connectors/hits/connectHits';

let assets, paths, queryId, indexName, isSaved, showContactNumber, isVisitor, paginationTarget, resultCard, footer, localeValue;

const CarPath = (slug) => paths.car_listing_path.replace(/--slug--/, slug)
const CarPathForHref = (slug, position) =>  `${CarPath(slug)}?qid=${queryId}&p=${position}&i=${indexName}`;
const addToSavedCarsPath = (id) => paths.saved_cars_add_path.replace(/--id--/, id);
const removeFromSavedCarsPath = (id) => paths.saved_cars_remove_path.replace(/--id--/, id);
const createLeadsPath = () => paths.create_leads_path;

const carTitle = (title,slug, position, trim) => {
	return	`<h5>
            <a class="text-dark fs-0 fs-lg-1 aa-click" href="${CarPathForHref(slug, position)}">
              ${title}
              <span class="fs--1">${ trim != '' ? `(${ trim })` : '' }</span>
            </a>
          </h5>`
}

const overallRating = (overall_rating) => {
  let percentage = (overall_rating).toFixed(0);

  return  `<div class="badge rounded-pill bg-soft-dark" data-bs-toggle="tooltip" title="CarCheck Rating">
            <img src="${ assets.overall_rating }"/>
            <span class="text-dark align-middle fs--1">${percentage}%</span>
          </div>`
}

const carPrice = ({ price_in_lakh, licence_status, exchangeable }) => {
  const displayPrice =  parseInt(price_in_lakh) === 0 ? "Call for price." : `${price_in_lakh} Lakh`;

  return `<div class="d-flex d-lg-block mb-3 align-items-center">
            <h4 class="fs-2 text-warning mb-0">${displayPrice}</h4>
            <h5 class="fs--1 text-500 mb-0 mt-1 ms-1 ms-lg-0">
              ${licence_status}
              ${ exchangeable ? "<i class='fas fa-exchange-alt' data-bs-toggle='tooltip' title='This car is exchangeable'></i>" : ''}
            </h5>
          </div>`
}

const carImage = (images, car_type, slug, position) => {
  return  `<div class="position-relative h-sm-100">
            <a class="d-block h-100 aa-click-image" href="${CarPathForHref(slug, position)}">
              <img class="img-fluid fit-cover rounded ${ car_type ? "w-100 h-100" : "w-80 h-80"} absolute-sm-centered"
                    src="${ car_type ? images.medium_url : images.thumb_url}"
                    alt="Car Listing Thumbnail"
              />
            </a>
            ${car_type ? carType(car_type) : '' }
            <div class="badge rounded-pill bg-dark-translucent position-absolute bottom-0 start-0 ms-2 mb-2 fs--2 z-index-2">
              <span>${images.count}</span><i class="fas fa-images ms-1"></i>
            </div>
          </div>`
}

const carTypeIcon = {
  "Featured": 'fa fa-star',
  "Premium Assist": 'fa fa-check-circle',
  "Top Spot": 'fas fa-arrow-circle-up'
}

const carType = (car_type) => {
  return `<div class="badge rounded-pill bg-dark-translucent position-absolute top-0 start-0 ms-2 mt-2 fs--2 z-index-2">
            <i class="${ carTypeIcon[car_type] } me-1"></i><span>${car_type}</span>
          </div>`
}

const carAttributes = (item) => {
  return `<div class="col-lg-8 d-flex justify-content-between d-md-block d-lg-block border-lg-right mb-3 mb-lg-0">
            <div class="row mb-lg-2 g-0">
              <div class="col-6 mb-1 text-truncate">
                <img class="me-1" src="${ assets.engine_power }" data-bs-toggle="tooltip" title="Engine Power" />
                ${item.engine_power} CC
              </div>
              <div class="col-6 mb-1 text-truncate">
                <img class="me-1" src="${ assets.fuel }" data-bs-toggle="tooltip" title="Fuel Type" />
                ${item.fuel_type.name}
              </div>
              <div class="col-6 mb-1 text-truncate">
                <img class="me-1" src="${ assets.location }" data-bs-toggle="tooltip" title="Location" />
                ${item.state.name}
              </div>
              <div class="col-6 mb-1 text-truncate">
                <img class="me-1" src="${ assets.transmission }" data-bs-toggle="tooltip" title="Transmission" />
                ${item.transmission.name}
              </div>
              <div class="col-6 mb-1 text-truncate">
                <img class="me-1" src="${ assets.mileage }" data-bs-toggle="tooltip" title="Mileage" />
                ${item.mileage_range}
              </div>
              <div class="col-6 mb-1 text-truncate">
                <img class="me-1" src="${ assets.licence }" data-bs-toggle="tooltip" title="Plate Number" />
                ${item.plate_number}
              </div>
            </div>
          </div>`
}

const dealer = (user) => {
  return `<div class="d-block border-left border-2 border-lg-0 border-primary ps-3 my-3 ps-lg-0">
            <a href="/dealers/${ user.username }">
              <img src="${ user.avatar }" width="32" height="32" class="me-1" /><span class="fs--1">${ user.name }
              </span>
            </a>
          </div>`
}

const lotNumber = (lot_number) =>{
  return `<div class="d-none d-lg-block col-lg-3 col-xl-4 pt-3">
            <span class="fs--1">Lot Number:</span>
            <span class="fs--1">#${lot_number}</span>
          </div>`
}

const certifiedBy = (inspector) => {
  if(!inspector.hasOwnProperty('name')){
    return `<div class="col-lg-4"></div>`;
  }

  return `<div class="col-lg-4 border-bottom border-lg-0 pt-lg-3">
            <div class="border-start border-2 border-primary border-lg-0 ps-3 my-3 my-lg-0 ps-lg-0">
              <div class="d-flex align-items-center">
                <img src="${ inspector.avatar }" width='32' height='32' class='me-1' />
                <span class="fs--1">
                Certified by <br />${ inspector.name }
                </span>
              </div>
            </div>
          </div>`
}

const buttons = ({ objectID: id, slug, user: { contact_number } }) => {
  let call_button = showContactNumber ?
                `<a class="col-6 col-lg-12 btn btn-sm btn-carsdb-secondary mt-lg-0 mb-lg-2 me-1 me-lg-0"
                    href="tel:${contact_number}"
                    data-controller="car-listing--call-button remember-visitor-lead"
                    data-action="car-listing--call-button#call remember-visitor-lead#remember"
                    data-car-listing--call-button-action="CarListing::Search::Call"
                    data-car-listing--call-button-label='${CarPath(slug)}'
                    data-car-listing--call-button-leads-path-value='${createLeadsPath()}'
                    data-car-listing--call-button-id-value='${id}'
                    data-remember-visitor-lead-is-visitor-value="${isVisitor}">
                  <i class="fas fa-phone-alt"></i>
                  <span data-car-listing--call-button-target="text" class="d-none d-md-inline-block">Call</span>
                </a>` :
                `<a class="col-6 col-lg-12 btn btn-sm btn-carsdb-secondary mt-lg-0 mb-lg-2 me-1 me-lg-0"
                    href='#'
                    data-bs-toggle="modal"
                    data-bs-target="#login-to-call-seller-modal">
                    <i class="fas fa-phone-alt"></i>
                    <span class="d-none d-md-inline-block">Call</span>
                </a>`
  return `<div class="col-lg-5 col-xl-4 d-flex flex-lg-column pt-3">
            ${call_button}
            <a class="col-6 col-lg-12 btn btn-sm btn-carsdb-default ms-1 ms-lg-0"
              href="#"
              data-controller="car-listing--save-button"
              data-action="car-listing--save-button#toggleSave car-listing--save-button.toggled->cars-search#updateSavedCars"
              data-car-listing--save-button-action-prefix-value="CarListing::Search::SavedCars::"
              data-car-listing--save-button-label-value='${CarPath(slug)}'
              data-car-listing--save-button-add-path-value='${addToSavedCarsPath(id)}'
              data-car-listing--save-button-remove-path-value='${removeFromSavedCarsPath(id)}'
              data-car-listing--save-button-is-saved-value='${isSaved(id)}'
              data-car-listing--save-button-car-id-value='${id}'>
              <i class="far fa-bookmark" data-car-listing--save-button-target="icon"></i>
              <span class="d-none d-md-inline-block" data-car-listing--save-button-target="text">Add</span>
            </a>
          </div>`
}

const getCarType = (car_type_array) => {
  return car_type_array.filter((v) => v !== 'Exchangeable')[0];
}

const renderHit = (item) => {
  return `<div class="col-12 p-card ${item.__position%2 !== 0 ? "" : "bg-100"}">
            <div class="row">
              <div class="col-sm-5 col-md-4 col-lg-4">
                ${carImage(item.images, getCarType(item.car_type), item.slug, item.__position)}
              </div>
              <div class="col-sm-7 col-md-8 col-lg-8">
                <div class="my-3 d-flex justify-content-between align-items-center mt-md-0">
                  ${carTitle(item.title, item.slug, item.__position, item.trim)}
                  ${item.overall_rating ? overallRating(item.overall_rating) : ''}
                </div>
                <div class="row border-bottom">
                  ${carAttributes(item)}
                  <div class="col-lg-4 d-none d-lg-flex flex-column justify-content-around">
                    ${carPrice(item)}
                    <div class="py-2">
                      ${item.is_dealer_car == true ? dealer(item.user) : ''}
                    </div>
                  </div>
                  <div class="d-flex d-lg-none">
                    ${carPrice(item)}
                  </div>
                  <div class="d-flex d-lg-none ${item.is_dealer_car == true ? "border-top" : '' } ">
                    ${item.is_dealer_car == true ? dealer(item.user) : ''}
                  </div>
                </div>
                <div class="row">
                  ${lotNumber(item.lot_number)}
                  ${certifiedBy(item.inspector)}
                  ${buttons(item)}
                </div>
              </div>
            </div>
          </div>`
}

const addEventListener = (div, hits) => {
  div.querySelector('.aa-click').addEventListener('click', (e) => {
    sendEvent('click', hits[i], 'Result link clicked');
  })
  div.querySelector('.aa-click-image').addEventListener('click', (e) => {
    sendEvent('click', hits[i], 'Result image clicked');
  })
  div.querySelector('.btn-carsdb-secondary').addEventListener('click', (e) => {
    sendEvent('conversion', hits[i], 'Call button clicked');
  })
  div.querySelector('.btn-carsdb-default').addEventListener('click', (e) => {
    sendEvent('conversion', hits[i], 'Add button clicked');
  })
}

const noSearchResult = () => {
  const no_result_title = {
    en: `No car listing found`,
    my: `ဝမ်းနည်းပါတယ်၊`
  }
  const no_result_desc = {
    en: `Sorry! We cound not find any results against your search criteria.`,
    my: `သင်ရှာနေသောကားအမျိုးအစား မရှိသေးပါ။ ကားရှာဖွေမှုကိုအစက ပြန်စမယ်။`
  }

  let array = new Array();
  let location = window.location.origin
  let pathname = "/car-listings/search/"
  array[0] =  [
    `<div class="text-center">
      <div class="m-3">
        <h5 class="card-title">${no_result_title[localeValue]}</h5>
        <p class="card-text">${no_result_desc[localeValue]}</p>
        <a href="${location}${pathname}" class="btn btn-primary">Reset filters</a>
      </div>
      <img src="${ assets.no_search_result }" />
    </div>`];
  return array[0]
}

const topBannerTemplate = (hitsLength) => {
  // Don't show top banner if there are zero results
  if(hitsLength == 0){
    return '';
  }

  return `<div class="col-12 p-card bg-200 d-none"></div>`
}

const midBannerTemplate = (hitsLength) => {
  // Don't show mid banner if there are less than 4 results
  if(hitsLength < 4){
    return '';
  }

  return `<div class="col-12 p-card bg-200 d-none"></div>`
}

export const renderHits = (renderOptions, isFirstRender) => {
  // Rendering logic
  const {
    hits, //object[]
    sendEvent, //fn
    widgetParams, // object
  } = renderOptions;

  indexName = renderOptions.instantSearchInstance.indexName

  if (isFirstRender) {
    // Do some initial rendering and bind events
  }

  // Render the widget
  if(hits[0]) {queryId = hits[0].__queryID}
  const renderedHits = hits[0] ? hits.map(renderHit) : noSearchResult();
  const topBanner = topBannerTemplate(hits.length);
  const midBanner = midBannerTemplate(hits.length);

  widgetParams.container.innerHTML = '';
  renderedHits.forEach((hitString, i) => {
    let div = document.createElement('div');
    div.innerHTML = hitString;

    if(div.querySelector('.aa-click'))
    {
      // analytics events
      addEventListener(div, hits)
      resultCard.classList.replace('bg-transparent','bg-white');
      resultCard.style.boxShadow = "";
      paginationTarget.classList.remove('d-none');
      footer.classList.remove('d-none');
    }
    else {
      resultCard.classList.add('bg-transparent');
      resultCard.style.boxShadow = "none";
      paginationTarget.classList.add('d-none');
      footer.classList.add('d-none');
    }

    widgetParams.container.appendChild(div.firstChild);
    div.remove()

    if(i == 2 || i == 6){
      let adDiv = document.createElement('div')
      adDiv.innerHTML = i == 2 ? topBanner : midBanner;

      widgetParams.container.appendChild(adDiv.firstChild);
      adDiv.remove()
    }
  });

  const event = new Event('algolia.car_hits.rendered');
  widgetParams.container.dispatchEvent(event);
};

export const getCarHits = ({ assets: assetsJson,
                                 paths: pathsJson,
                                 isSaved: isSavedFunction,
                                 showContactNumber: showContactNumberBool,
                                 isVisitor: isVisitorBool,
                                 paginationTarget: paginationTargetObj,
                                 resultCard: resultCardObj,
                                 footer: footerObj,
                                 localeValue: localeValueObj }) => {
  assets = assetsJson;
  paths = pathsJson;
  isSaved = isSavedFunction
  showContactNumber = showContactNumberBool
  isVisitor = isVisitorBool
  paginationTarget = paginationTargetObj
  resultCard = resultCardObj
  footer = footerObj
  localeValue= localeValueObj
  return connectHits(renderHits);
}
