import { Controller } from 'stimulus';
import { cable } from '@hotwired/turbo-rails';
import { get } from '@rails/request.js';
import { findElement } from 'src/v3/utils';
import { Alert } from 'bootstrap';

export default class extends Controller {
  static values = {
    id: Number,
    userId: String,
    url: String
  }
  async connect(){
    this.consumer = await cable.createConsumer();
    this.subscription = this.consumer.subscriptions.create({ channel: 'AuctionChannel', id: this.idValue }, {
      received: this.onDataReceived.bind(this)
    });
  }

  disconnect(){
    this.subscription.unsubscribe();
    this.consumer.disconnect();
  }

  onDataReceived(data){
    const shownBidId = findElement(`#bid-component-for-auction-${this.idValue}`).dataset.highestBidId;

    if(data.event == 'new_bid' && shownBidId == data.bid_id) { return }

    get(this.urlValue, {
      responseKind: 'turbo-stream'
    })
  }

  removeAlert(e){
    var alertNode = document.querySelector('.alert');
    if(alertNode != null){
      let alertInstance = new Alert(alertNode);
      alertInstance.close();
    }
  }

}
