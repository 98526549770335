/**
 * Loan Calculator for BMF's financing
 **/

var down_payment = 0;
var selling_price = 0;
var down_payment_rate = 0.5;
var interest_rate = 0.18;
var loan_terms =  0;
var remain_amount =  0;

export function init(price, loan, payment_rate){
  selling_price = price;
  loan_terms = loan;
  down_payment_rate = payment_rate;
  calculate_down_payment();
  remain_amount = selling_price - down_payment;
}

export function monthly_payment(){
  return (monthly_principle() + monthly_rental_fee()).toFixed(2);
}

export function monthly_principle(){
  return (remain_amount / loan_terms)
}

export function monthly_rental_fee(){
  return (rental_fee() / loan_terms);
}

export function rental_fee(){
  return (remain_amount * (interest_rate ) * (loan_terms / 12));
}

export function initial_payment(){
  return (down_payment + ( (selling_price - down_payment) * 0.005) + ( selling_price * 0.01 ) + ( selling_price * 0.01)).toFixed(2);
}

export function calculate_down_payment(){
  down_payment = selling_price * down_payment_rate;
}

export function stamp_fees(){
  return (down_payment * 0.005);
}

export function sales_commission(){
  return ((selling_price - down_payment) * 0.01);
}

export function service_charges(){
  return (down_payment * 0.01);
}
