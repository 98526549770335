import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "amount", "tip", "downpayment", "year", "currency", "price" ]

  connect() {
    this.amountTarget.innerHTML = this.result()
    this.tipTarget.title = this.tip()
  }
  calculate() {
    this.amountTarget.innerHTML = this.result()
    this.tipTarget.dataset.originalTitle = this.tip()
  }

  result(){
    let downpayment = parseInt(this.downpaymentTarget.value)
    let year = parseInt(this.yearTarget.value)
    let car_price = parseInt(this.priceTarget.value)
    let form_currency = this.currencyTarget.value
    let periods = year * 12;
    let rate = 0.1 / 12;
    let divider = (form_currency == "MMK") ? 100000 : 1
    let currency = (form_currency == "MMK") ? "Lakhs" : "USD"
    let loan_amount = car_price - (car_price * downpayment/100)
    let payment = Math.round(loan_amount * rate / (1 - (Math.pow(1/(1 + rate), periods)))/divider)

    return `${payment} ${currency}/ month`
  }

  tip(){
    return  `This car price is calculated based on 10% interest rate with
            ${this.downpaymentTarget.value}% downpayment
            for ${this.yearTarget.value} years`
  }

}
