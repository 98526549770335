export function calculate(carType, enginePower, actualPrice, exchangeRate) {
  let { customDuty, customDutyFormula } = calculateCustomDuty(carType, enginePower, actualPrice)
  let { specialCommercialTax, specialCommercialTaxFormula } = calculateSpecialCommercialTax(carType, enginePower, actualPrice, customDuty);
  let { commercialTax, commercialTaxFormula } = calculateCommercialTax(actualPrice, customDuty, specialCommercialTax);
  let { rtadTax, rtadTaxFormula } = calculateRtadTax(carType, enginePower, actualPrice)

  let totalCostInDollar = customDuty + specialCommercialTax + commercialTax + rtadTax + actualPrice;

  let harborTax = parseInt(customDuty + specialCommercialTax + commercialTax)

  let { incomeTax, incomeTaxFormula } = calculateIncomeTax(carType, actualPrice, harborTax, exchangeRate);

  let totalCostWithIncomeTaxInDollar = totalCostInDollar + incomeTax;

  return {
    customDuty, customDutyFormula,
    specialCommercialTax, specialCommercialTaxFormula,
    commercialTax, commercialTaxFormula,
    rtadTax, rtadTaxFormula,
    totalCostInDollar,
    totalCostWithIncomeTaxInDollar,
    incomeTax, incomeTaxFormula
  }
}

function calculateCustomDuty(carType, enginePower, actualPrice) {
  let calculators = {
    passenger:       () => enginePower <= 2000 ? actualPrice * 0.2 : actualPrice * 0.3,
    'passenger-gov': () => enginePower <= 2000 ? actualPrice * 0.05 : actualPrice * 0.1,
    commercial:      () => actualPrice * 0.1,
    electric:        () => actualPrice * 0.2
  }

  let formulas = {
    passenger:       () => enginePower <= 2000 ? 'AV x 20%' : 'AV x 30%',
    'passenger-gov': () => enginePower <= 2000 ? 'AV x 5%' : 'AV x 10%',
    commercial:      () => 'AV x 10%',
    electric:        () => 'AV x 20%'
  }

  return {
    customDuty: calculators[carType](),
    customDutyFormula: formulas[carType]()
  }
}

function calculateSpecialCommercialTax(carType, enginePower, actualPrice, customDuty) {
  const calculator = () => {
    const priceWithDuty = customDuty + actualPrice;
    if(enginePower <= 1500){
      return 0
    }else if(enginePower > 1500 && enginePower <= 2000){
      return priceWithDuty * 0.1
    }else if(enginePower > 2000 && enginePower <= 4000){
      return priceWithDuty * 0.3
    }else{
      return priceWithDuty * 0.5;
    }
  };

  const formula = () => {
    const prefix = "(AV+အကောက်ခွန်) x"
    if(enginePower <= 1500){
      return ''
    }else if(enginePower > 1500 && enginePower <= 2000){
      return `${prefix} 10%`
    }else if(enginePower > 2000 && enginePower <= 4000){
      return `${prefix} 30%`
    }else{
      return `${prefix} 50%`
    }
  };

  if(carType == 'passenger' || carType == 'passenger-gov'){
    return {
      specialCommercialTax: calculator(),
      specialCommercialTaxFormula: formula()
    }
  }else{
    return {
      specialCommercialTax: 0,
      specialCommercialTaxFormula: ''
    }
  }
}

function calculateCommercialTax(actualPrice, customDuty, specialCommercialTax) {
  return {
    commercialTaxFormula: "(AV+ အကောက်ခွန် + အထူးကုန်စည်ခွန်) x 5%",
    commercialTax: (actualPrice + customDuty + specialCommercialTax) * 0.05
  }
}

function calculateRtadTax(carType, enginePower, actualPrice) {
  let rtad_tax_discount = 0.8333;
  const calculators = {
    passenger: () => {
      if(enginePower <= 1350) {
        return actualPrice * rtad_tax_discount * 0.15;
      } else if(enginePower > 1350 && enginePower <= 2000) {
        return actualPrice * rtad_tax_discount * 0.25;
      } else if(enginePower > 2000 && enginePower <= 5000) {
        return actualPrice * rtad_tax_discount * 0.4;
      } else {
        return actualPrice * rtad_tax_discount * 0.6;
      }
    },
    'passenger-gov': () => {
      if(enginePower <= 1350) {
        return actualPrice * rtad_tax_discount * 0;
      } else if(enginePower > 1350 && enginePower <= 2000) {
        return actualPrice * rtad_tax_discount * 0.05;
      } else if(enginePower > 2000 && enginePower <= 5000) {
        return actualPrice * rtad_tax_discount * 0.1;
      } else {
        return actualPrice * rtad_tax_discount * 0.15;
      }
    },
    commercial: () => actualPrice * rtad_tax_discount * 0.05,
    electric: () => actualPrice * rtad_tax_discount * 0
  }

  let prefix = 'AV x 0.8333 x'
  const formulas = {
    passenger: (enginePower) => {
      if(enginePower <= 1350) {
        return `${prefix} 15%`;
      } else if(enginePower > 1350 && enginePower <= 2000) {
        return `${prefix} 25%`;
      } else if(enginePower > 2000 && enginePower <= 5000) {
        return `${prefix} 40%`;
      } else {
        return `${prefix} 60%`;
      }
    },
    'passenger-gov': () => {
      if(enginePower <= 1350) {
        return `${prefix} 0%`;
      } else if(enginePower > 1350 && enginePower <= 2000) {
        return `${prefix} 5%`;
      } else if(enginePower > 2000 && enginePower <= 5000) {
        return `${prefix} 10%`;
      } else {
        return `${prefix} 15%`;
      }
    },
    commercial: () => `${prefix} 5%`,
    electric: () => `${prefix} 0%`
  }

  return {
    rtadTax: calculators[carType](),
    rtadTaxFormula: formulas[carType]()
  }
}

function calculateIncomeTax(carType, actualPrice, harborTax, exchangeRate) {
  const totalCarAmount = actualPrice + harborTax
  const totalCarAmountInKyat = totalCarAmount * exchangeRate
  const prefix = '(AV +ဆိပ်ကမ်းခွန်) x'

  let incomeTaxFormula, incomeTax; 

  if(carType == 'passenger-gov'){
    incomeTaxFormula = `${prefix} 6%`;
    incomeTax = totalCarAmount * 0.06;
  } else {
    if(totalCarAmountInKyat <= 100000000) {
      incomeTaxFormula = `${prefix} 6%`;
      incomeTax = totalCarAmount * 0.06;
    } else if(totalCarAmountInKyat > 100000000 && totalCarAmountInKyat <= 300000000) {
      incomeTaxFormula = `${prefix} 10%`;
      incomeTax = totalCarAmount * 0.1;
    } else if(totalCarAmountInKyat > 300000000 && totalCarAmountInKyat <= 1000000000) {
      incomeTaxFormula = `${prefix} 20%`;
      incomeTax = totalCarAmount * 0.2;
    } else if(totalCarAmountInKyat > 1000000000 && totalCarAmountInKyat <= 3000000000) {
      incomeTaxFormula = `${prefix} 30%`;
      incomeTax = totalCarAmount * 0.3;
    } else {
      incomeTaxFormula = `${prefix} 60%`;
      incomeTax = totalCarAmount * 0.6;
    }
  }

  return { incomeTax, incomeTaxFormula }
}
