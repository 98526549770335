import { Controller } from "stimulus";
import { findElement } from '../src/v3/utils'
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  redirectToMy() {
    Turbo.visit(findElement('link[hreflang="my-mm"]').href);
  }
  redirectToEn() {
    Turbo.visit(findElement('link[hreflang="en"]').href);
  }
}
