import { Modal } from "bootstrap";
import { findElement } from "src/v3/utils";

const isDashboardInventoryPage = (body) => {
  return body.classList.contains('dashboard') && body.classList.contains('inventory')
}

const initBumpListingModal   = () => {
  if(!isDashboardInventoryPage(document.body)){
    return;
  }

  let inventoryUrlParams = new URLSearchParams(window.location.search);
  let isBumpRequested = inventoryUrlParams.get('bump');
  let showBumpEl = findElement('#bump-listing-modal-v3');
  if(!isBumpRequested || !showBumpEl) {
    return;
  }

  let modal = new Modal(showBumpEl).show();
};

export default initBumpListingModal;