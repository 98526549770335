import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['output', 'input']

  update(e) {
    let lastChar = event.target
                        .value
                        .charAt(event.target.selectionStart - 1)
                        .charCodeAt()
    if(lastChar === 32){
      let newTag = document.createElement('button');
      let value = e.target.value.trim().toLowerCase();
      newTag.className = 'btn btn-sm btn-primary me-1 mb-1'
      newTag.innerHTML = value
      newTag.dataset.action = 'click->ems--enquiry-tagging#remove'
      this.outputTarget.appendChild(newTag)
      e.target.value = ''
      this.inputTarget.value += value +','
    }
  }

  remove(e) {
    e.preventDefault()
    let target = e.target.innerHTML
    let tags = this.inputTarget.value
    let value = tags.replace(target, '')
    this.inputTarget.value = value
    e.target.remove()
  }
}
