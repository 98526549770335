export const trackEventWithData = (e) => {
  let { gtmAction, gtmCategory, gtmLabel } = e.currentTarget.dataset;
  trackEvent({
    action: gtmAction,
    category: gtmCategory,
    label: gtmLabel
  });
};

export const trackEvent = (params) => {
  const event = {
    event: 'GAEvent:Generic',
    eventAction: params.action,
    eventCategory: params.category,
    eventLabel: params.label
  };
  window.dataLayer.push(event);
}

export const initGTMDataTracker = () => {
  let elementList = [].slice.call(document.querySelectorAll('[data-gtm-action]'))
  elementList.map((element) => {
    const event = element.dataset.gtmOn || 'click';
    element.addEventListener(event, trackEventWithData);
  })
};
