import { Controller } from 'stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static values = {
    'url': String
  }

  static targets = [
    'userNameAvailableField',
    'uniqueUserNameField',
    'messageField'
   ]

  connect() {
    // nothing to do here.
  }

  keyUpUserName(e) {
    if(this.uniqueUserNameFieldTarget.value == '') {
      this.userNameAvailableFieldTarget.classList.add('d-none');
    } else {
      this.userNameAvailableFieldTarget.classList.remove('d-none');
    }
    this.messageFieldTarget.innerHTML = ''
  }

  async checkUniqueUserName(e) {
    e.preventDefault();
    if(this.unique_user_name == '') { return; }

    const response = await post(this.urlValue, {
      body: {
        user: {
          username: this.uniqueUserNameFieldTarget.value
        }
      },
      responseKind: 'json' 
    });

    if(response.ok){
      const body = await response.json
      this.userNameAvailableFieldTarget.classList.add('d-none');
      if (body.success) {
        this.messageFieldTarget.innerHTML = `<span class='text-primary'>Username is available. <i class='fa fa-check-circle'></i></span>`
      } else {
        this.messageFieldTarget.innerHTML = `<span class='text-warning'>${ body.message } <i class='fa fa-times-circle'></i></span>`
      }
    }else{
      console.error(response);
    }
  }
}
