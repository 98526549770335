export const amiValue = {
  data: {
    "u15": {
      "50": "41200",
      "60": "46200",
      "70": "51200",
      "80": "56200",
      "90": "61200",
      "100": "66200",
      "110": "71200",
      "120": "76200",
      "130": "81200",
      "140": "86200",
      "150": "91200",
      "160": "96200",
      "170": "101200",
      "180": "106200",
      "190": "111200",
      "200": "116200",
      "210": "121200",
      "220": "126200",
      "230": "131200",
      "240": "136200",
      "250": "141200",
      "260": "146200",
      "270": "151200",
      "280": "156200",
      "290": "161200",
      "300": "166200",
      "310": "171200",
      "320": "176200",
      "330": "181200",
      "340": "186200",
      "350": "191200",
      "360": "196200",
      "370": "201200",
      "380": "206200",
      "390": "211200",
      "400": "216200",
      "410": "221200",
      "420": "226200",
      "430": "231200",
      "440": "236200",
      "450": "241200",
      "460": "246200",
      "470": "251200",
      "480": "256200",
      "490": "261200",
      "500": "266200",
      "510": "271200",
      "520": "276200",
      "530": "281200",
      "540": "286200",
      "550": "291200",
      "560": "296200",
      "570": "301200",
      "580": "306200",
      "590": "311200",
      "600": "316200",
      "610": "321200",
      "620": "326200",
      "630": "331200",
      "640": "336200",
      "650": "341200",
      "660": "346200",
      "670": "351200",
      "680": "356200",
      "690": "361200",
      "700": "366200",
      "710": "371200",
      "720": "376200",
      "730": "381200",
      "740": "386200",
      "750": "391200",
      "760": "396200",
      "770": "401200",
      "780": "406200",
      "790": "411200",
      "800": "416200",
      "810": "421200",
      "820": "426200",
      "830": "431200",
      "840": "436200",
      "850": "441200",
      "860": "446200",
      "870": "451200",
      "880": "456200",
      "890": "461200",
      "900": "466200",
      "910": "471200",
      "920": "476200",
      "930": "481200",
      "940": "486200",
      "950": "491200",
      "960": "496200",
      "970": "501200",
      "980": "506200",
      "990": "511200",
      "1000": "516200",
      "1100": "566200",
      "1200": "616200",
      "1300": "666200",
      "1400": "716200",
      "1500": "766200",
      "1600": "816200",
      "1700": "866200",
      "1800": "916200",
      "1900": "966200",
      "2000": "1016200",
      "2100": "1066200",
      "2200": "1116200",
      "2300": "1166200",
      "2400": "1216200",
      "2500": "1266200",
      "2600": "1316200",
      "2700": "1366200",
      "2800": "1416200",
      "2900": "1466200",
      "3000": "1516200"
    },
    "r20": {
      "50": "41400",
      "60": "46400",
      "70": "51400",
      "80": "56400",
      "90": "61400",
      "100": "66400",
      "110": "71400",
      "120": "76400",
      "130": "81400",
      "140": "86400",
      "150": "91400",
      "160": "96400",
      "170": "101400",
      "180": "106400",
      "190": "111400",
      "200": "116400",
      "210": "121400",
      "220": "126400",
      "230": "131400",
      "240": "136400",
      "250": "141400",
      "260": "146400",
      "270": "151400",
      "280": "156400",
      "290": "161400",
      "300": "166400",
      "310": "171400",
      "320": "176400",
      "330": "181400",
      "340": "186400",
      "350": "191400",
      "360": "196400",
      "370": "201400",
      "380": "206400",
      "390": "211400",
      "400": "216400",
      "410": "221400",
      "420": "226400",
      "430": "231400",
      "440": "236400",
      "450": "241400",
      "460": "246400",
      "470": "251400",
      "480": "256400",
      "490": "261400",
      "500": "266400",
      "510": "271400",
      "520": "276400",
      "530": "281400",
      "540": "286400",
      "550": "291400",
      "560": "296400",
      "570": "301400",
      "580": "306400",
      "590": "311400",
      "600": "316400",
      "610": "321400",
      "620": "326400",
      "630": "331400",
      "640": "336400",
      "650": "341400",
      "660": "346400",
      "670": "351400",
      "680": "356400",
      "690": "361400",
      "700": "366400",
      "710": "371400",
      "720": "376400",
      "730": "381400",
      "740": "386400",
      "750": "391400",
      "760": "396400",
      "770": "401400",
      "780": "406400",
      "790": "411400",
      "800": "416400",
      "810": "421400",
      "820": "426400",
      "830": "431400",
      "840": "436400",
      "850": "441400",
      "860": "446400",
      "870": "451400",
      "880": "456400",
      "890": "461400",
      "900": "466400",
      "910": "471400",
      "920": "476400",
      "930": "481400",
      "940": "486400",
      "950": "491400",
      "960": "496400",
      "970": "501400",
      "980": "506400",
      "990": "511400",
      "1000": "516400",
      "1100": "566400",
      "1200": "616400",
      "1300": "666400",
      "1400": "716400",
      "1500": "766400",
      "1600": "816400",
      "1700": "866400",
      "1800": "916400",
      "1900": "966400",
      "2000": "1016400",
      "2100": "1066400",
      "2200": "1116400",
      "2300": "1166400",
      "2400": "1216400",
      "2500": "1266400",
      "2600": "1316400",
      "2700": "1366400",
      "2800": "1416400",
      "2900": "1466400",
      "3000": "1516400"
    },
    "r30": {
      "50": "41700",
      "60": "46700",
      "70": "51700",
      "80": "56700",
      "90": "61700",
      "100": "66700",
      "110": "71700",
      "120": "76700",
      "130": "81700",
      "140": "86700",
      "150": "91700",
      "160": "96700",
      "170": "101700",
      "180": "106700",
      "190": "111700",
      "200": "116700",
      "210": "121700",
      "220": "126700",
      "230": "131700",
      "240": "136700",
      "250": "141700",
      "260": "146700",
      "270": "151700",
      "280": "156700",
      "290": "161700",
      "300": "166700",
      "310": "171700",
      "320": "176700",
      "330": "181700",
      "340": "186700",
      "350": "191700",
      "360": "196700",
      "370": "201700",
      "380": "206700",
      "390": "211700",
      "400": "216700",
      "410": "221700",
      "420": "226700",
      "430": "231700",
      "440": "236700",
      "450": "241700",
      "460": "246700",
      "470": "251700",
      "480": "256700",
      "490": "261700",
      "500": "266700",
      "510": "271700",
      "520": "276700",
      "530": "281700",
      "540": "286700",
      "550": "291700",
      "560": "296700",
      "570": "301700",
      "580": "306700",
      "590": "311700",
      "600": "316700",
      "610": "321700",
      "620": "326700",
      "630": "331700",
      "640": "336700",
      "650": "341700",
      "660": "346700",
      "670": "351700",
      "680": "356700",
      "690": "361700",
      "700": "366700",
      "710": "371700",
      "720": "376700",
      "730": "381700",
      "740": "386700",
      "750": "391700",
      "760": "396700",
      "770": "401700",
      "780": "406700",
      "790": "411700",
      "800": "416700",
      "810": "421700",
      "820": "426700",
      "830": "431700",
      "840": "436700",
      "850": "441700",
      "860": "446700",
      "870": "451700",
      "880": "456700",
      "890": "461700",
      "900": "466700",
      "910": "471700",
      "920": "476700",
      "930": "481700",
      "940": "486700",
      "950": "491700",
      "960": "496700",
      "970": "501700",
      "980": "506700",
      "990": "511700",
      "1000": "516700",
      "1100": "566700",
      "1200": "616700",
      "1300": "666700",
      "1400": "716700",
      "1500": "766700",
      "1600": "816700",
      "1700": "866700",
      "1800": "916700",
      "1900": "966700",
      "2000": "1016700",
      "2100": "1066700",
      "2200": "1116700",
      "2300": "1166700",
      "2400": "1216700",
      "2500": "1266700",
      "2600": "1316700",
      "2700": "1366700",
      "2800": "1416700",
      "2900": "1466700",
      "3000": "1516700"
    },
    "r40": {
      "50": "42000",
      "60": "47000",
      "70": "52000",
      "80": "57000",
      "90": "62000",
      "100": "67000",
      "110": "72000",
      "120": "77000",
      "130": "82000",
      "140": "87000",
      "150": "92000",
      "160": "97000",
      "170": "102000",
      "180": "107000",
      "190": "112000",
      "200": "117000",
      "210": "122000",
      "220": "127000",
      "230": "132000",
      "240": "137000",
      "250": "142000",
      "260": "147000",
      "270": "152000",
      "280": "157000",
      "290": "162000",
      "300": "167000",
      "310": "172000",
      "320": "177000",
      "330": "182000",
      "340": "187000",
      "350": "192000",
      "360": "197000",
      "370": "202000",
      "380": "207000",
      "390": "212000",
      "400": "217000",
      "410": "222000",
      "420": "227000",
      "430": "232000",
      "440": "237000",
      "450": "242000",
      "460": "247000",
      "470": "252000",
      "480": "257000",
      "490": "262000",
      "500": "267000",
      "510": "272000",
      "520": "277000",
      "530": "282000",
      "540": "287000",
      "550": "292000",
      "560": "297000",
      "570": "302000",
      "580": "307000",
      "590": "312000",
      "600": "317000",
      "610": "322000",
      "620": "327000",
      "630": "332000",
      "640": "337000",
      "650": "342000",
      "660": "347000",
      "670": "352000",
      "680": "357000",
      "690": "362000",
      "700": "367000",
      "710": "372000",
      "720": "377000",
      "730": "382000",
      "740": "387000",
      "750": "392000",
      "760": "397000",
      "770": "402000",
      "780": "407000",
      "790": "412000",
      "800": "417000",
      "810": "422000",
      "820": "427000",
      "830": "432000",
      "840": "437000",
      "850": "442000",
      "860": "447000",
      "870": "452000",
      "880": "457000",
      "890": "462000",
      "900": "467000",
      "910": "472000",
      "920": "477000",
      "930": "482000",
      "940": "487000",
      "950": "492000",
      "960": "497000",
      "970": "502000",
      "980": "507000",
      "990": "512000",
      "1000": "517000",
      "1100": "567000",
      "1200": "617000",
      "1300": "667000",
      "1400": "717000",
      "1500": "767000",
      "1600": "817000",
      "1700": "867000",
      "1800": "917000",
      "1900": "967000",
      "2000": "1017000",
      "2100": "1067000",
      "2200": "1117000",
      "2300": "1167000",
      "2400": "1217000",
      "2500": "1267000",
      "2600": "1317000",
      "2700": "1367000",
      "2800": "1417000",
      "2900": "1467000",
      "3000": "1517000"
    },
    "a40": {
      "50": "42300",
      "60": "47300",
      "70": "52300",
      "80": "57300",
      "90": "62300",
      "100": "67300",
      "110": "72300",
      "120": "77300",
      "130": "82300",
      "140": "87300",
      "150": "92300",
      "160": "97300",
      "170": "102300",
      "180": "107300",
      "190": "112300",
      "200": "117300",
      "210": "122300",
      "220": "127300",
      "230": "132300",
      "240": "137300",
      "250": "142300",
      "260": "147300",
      "270": "152300",
      "280": "157300",
      "290": "162300",
      "300": "167300",
      "310": "172300",
      "320": "177300",
      "330": "182300",
      "340": "187300",
      "350": "192300",
      "360": "197300",
      "370": "202300",
      "380": "207300",
      "390": "212300",
      "400": "217300",
      "410": "222300",
      "420": "227300",
      "430": "232300",
      "440": "237300",
      "450": "242300",
      "460": "247300",
      "470": "252300",
      "480": "257300",
      "490": "262300",
      "500": "267300",
      "510": "272300",
      "520": "277300",
      "530": "282300",
      "540": "287300",
      "550": "292300",
      "560": "297300",
      "570": "302300",
      "580": "307300",
      "590": "312300",
      "600": "317300",
      "610": "322300",
      "620": "327300",
      "630": "332300",
      "640": "337300",
      "650": "342300",
      "660": "347300",
      "670": "352300",
      "680": "357300",
      "690": "362300",
      "700": "367300",
      "710": "372300",
      "720": "377300",
      "730": "382300",
      "740": "387300",
      "750": "392300",
      "760": "397300",
      "770": "402300",
      "780": "407300",
      "790": "412300",
      "800": "417300",
      "810": "422300",
      "820": "427300",
      "830": "432300",
      "840": "437300",
      "850": "442300",
      "860": "447300",
      "870": "452300",
      "880": "457300",
      "890": "462300",
      "900": "467300",
      "910": "472300",
      "920": "477300",
      "930": "482300",
      "940": "487300",
      "950": "492300",
      "960": "497300",
      "970": "502300",
      "980": "507300",
      "990": "512300",
      "1000": "517300",
      "1100": "567300",
      "1200": "617300",
      "1300": "667300",
      "1400": "717300",
      "1500": "767300",
      "1600": "817300",
      "1700": "867300",
      "1800": "917300",
      "1900": "967300",
      "2000": "1017300",
      "2100": "1067300",
      "2200": "1117300",
      "2300": "1167300",
      "2400": "1217300",
      "2500": "1267300",
      "2600": "1317300",
      "2700": "1367300",
      "2800": "1417300",
      "2900": "1467300",
      "3000": "1517300"
    }
  },
  thirdPartyData: {
    "u15": "6600",
    "r20": "7200",
    "r30": "8100",
    "r40": "9000",
    "a40": "9900"
  }
};
