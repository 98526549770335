import Lazyload from 'vanilla-lazyload';

const executeLazyFunction = (element) => {
  let lazyFunctionName = element.getAttribute('data-lazy-function');
  if(!window.lazyFunctions) return;

  let lazyFunction = window.lazyFunctions[lazyFunctionName];

  if(!lazyFunction) return;

  lazyFunction(element);
}

const initLazyload = () => {
  new Lazyload({
    unobserve_entered: true,
    callback_enter: executeLazyFunction
  })
};

export default initLazyload;
