import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller{
  connect(){ }
  toggle(e){
    Rails.ajax({
      type: 'PUT',
      url: e.target.dataset.url + `?notify=${e.target.checked}`
    });
  }
}