/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start();
require("@rails/activestorage").start();
import "@hotwired/turbo-rails";



require('pannellum/build/pannellum');

// if we don't require bootstrap, tabs, collapse and dropdowns won't work
import 'bootstrap';
import 'controllers';

import { turboLoad, initScrollToTopOnTurboSubmitFailed } from '../src/v3/utils';
initScrollToTopOnTurboSubmitFailed();

import { initGTMDataTracker } from '../src/v3/gtm_tracker';
import initLayoutFooterTracker from '../src/v3/layout/footer/tracker';
import initCarListingHitsRenderedListener from '../src/v3/car_listing/hits_rendered_listener';
import showCreditNotEnoughModal from '../src/v3/dashboard/credit_not_enough';
import initBumpListingModal from '../src/v3/dashboard/bump';
import initBrandNewCarModelGlightBox from '../src/v3/brand_new/car_model/glightbox';
import initBrandNewCompareModal from '../src/v3/brand_new/car_model/comparison';
import initTooltip from '../src/v3/init_tooltip';
import initAuctionGlightBox from '../src/v3/auction/glightbox';
import initLazyload from '../src/v3/lazyload';

turboLoad(initLazyload);
turboLoad(initGTMDataTracker);
turboLoad(initLayoutFooterTracker);
turboLoad(initCarListingHitsRenderedListener);
turboLoad(showCreditNotEnoughModal);
turboLoad(initBumpListingModal);
turboLoad(initBrandNewCarModelGlightBox);
turboLoad(initBrandNewCompareModal);
turboLoad(initTooltip);
turboLoad(initAuctionGlightBox);

import '../src/v3/car_listing/show';

// import 'channels/consumer'
