import { amiValue } from "./constants";

const nilExcess = 2000;

export default function calculate(engine_power, car_price, optionals) {
  let amiEngineKey =  calculateEngineKey(engine_power);
  let amiPrice =  normalizePrice(car_price);

  let result = {} 
  result.nilExcess =  nilExcess,
  result.basicPremiumCost =  parseInt(amiValue.data[amiEngineKey][amiPrice]);
  result.thirdPartyCost =  parseInt(amiValue.thirdPartyData[amiEngineKey]);
  result.amiWindScreenCost =  calculateWindScreen(car_price);
  result.srccCost =  parseInt(car_price * 100000 * 0.0005);
  result.warRiskIncludedCost =  calculateWarOfRisk(result.srccCost, optionals.warRiskIncluded);
  result.actOfGodIncludedCost =  calculateActOfGod(result.srccCost, optionals.actOfGodIncluded);
  result.theftIncludedCost =  calculateTheft(result.basicPremiumCost, optionals.theftIncluded);

  let total = Object.values(result).reduce((accumulator, value) => accumulator + value)
  
  return { result: result, total: total}
}

function calculateEngineKey(engine_power) {
  let engineKey;
  switch (true) {
    case (engine_power <= 1500):
      engineKey = 'u15';
      break;
    case (engine_power <= 2000):
      engineKey = 'r20';
      break;
    case (engine_power <= 3000):
      engineKey = 'r30';
      break;
    case (engine_power <= 4000):
      engineKey = 'r40';
      break;
    case (engine_power > 4000):
      engineKey = 'a40';
      break;
    default:
  }
  return engineKey;
}

function normalizePrice(price) {
  let normalizedPrice;
  switch (true) {
    case (price <= 50):
      normalizedPrice = 50;
      break;
    case (price >= 3000):
      normalizedPrice = 3000;
      break;
    default:
      normalizedPrice = price;
  }
  if (normalizedPrice > 1000) {
    normalizedPrice = Math.round(normalizedPrice / 100) * 100;
  } else {
    normalizedPrice = Math.round(normalizedPrice / 10) * 10;
  }
  return normalizedPrice;
}

function calculateWindScreen(price) {
  let windScreen;
  switch (true) {
    case (price <= 300):
      windScreen = 300000;
      break;
    case (price <= 500):
      windScreen = 500000;
      break;
    case (price <= 700):
      windScreen = 700000;
      break;
    case (price <= 1000):
      windScreen = 1000000;
      break;
    case (price <= 1500):
      windScreen = 1500000;
      break;
    default:
      windScreen = 2000000;
  }
  windScreen *= 0.05;
  return windScreen;
}

function calculateWarOfRisk(srcc, checked) {
  return checked ? srcc : 0;
};

function calculateActOfGod(srcc, checked) {
  return checked ? srcc : 0;
};

function calculateTheft(basicPremium, checked) {
  return checked ? basicPremium * 0.15 : 0;
};
