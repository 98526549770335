import { Controller } from 'stimulus';
import { trackEvent } from 'src/v3/gtm_tracker';

export default class extends Controller {
  connect(){
    this.initCollapseEventTracker();
  }

  initCollapseEventTracker(){
    const collapseEl = this.element.querySelector('.collapse');
    const category = this.data.get('category');

    const onShow = (e) => {
      trackEvent({
        category: category,
        action: 'CollapseShow',
        label: e.target.id
      });
    };

    const onHide = (e) => {
      trackEvent({
        category: category,
        action: 'CollapseHide',
        label: e.target.id
      })
    };

    collapseEl.addEventListener('show.bs.collapse', onShow);
    collapseEl.addEventListener('hide.bs.collapse', onHide);
  }

}
