import { Controller } from 'stimulus';
import { calculate } from 'src/v3/import_calculator';
import { trackEvent } from 'src/v3/gtm_tracker';

export default class extends Controller {
  static targets = [
    'enginePowerRow',
    'resultFieldset',
    'enginePowerField',
    'carPriceField',
    'exchangeRateField',
    'checkIncomeTaxField',
    'customDutyField',
    'customDutyFormulaField',
    'specialCommercialTaxField',
    'specialCommercialTaxFormulaField',
    'commercialTaxField',
    'commercialTaxFormulaField',
    'rtadTaxFormulaField',
    'rtadTaxField',
    'costInDollarField',
    'costInKyatField',
    'incomeTaxField',
    'totalCostInDollarField',
    'totalCostInKyatField',
    'customDutyKyatField',
    'specialCommercialTaxKyatField',
    'commercialTaxKyatField',
    'rtadTaxKyatField',
    'incomeTaxKyatField',
   ]

  connect() {
    //nothing to do here.
  }

  calculate(e) {
    e.preventDefault();
    trackEvent({
      action: 'CarPriceCalculator::Calculate',
      category: 'CarPriceCalculator',
      label: 'CarPriceCalculator'
    })

    let carType = new FormData(this.element).get('car-type-radio');
    let enginePower = parseInt(this.enginePowerFieldTarget.value);
    let actualPrice = parseInt(this.carPriceFieldTarget.value);
    let exchangeRate = parseInt(this.exchangeRateFieldTarget.value);

    let result = calculate(carType, enginePower, actualPrice, exchangeRate);

    this.showCalculateResult(result, exchangeRate);
    this.resultFieldsetTarget.scrollIntoView();
  }

  selectCarTypeOption(e){
    if(e.currentTarget.value == 'electric'){
      this.enginePowerRowTarget.classList.add('d-none');
    }else{
      this.enginePowerRowTarget.classList.remove('d-none');
    }
  }

  showCalculateResult(result, exchangeRate) {
    let { customDuty, customDutyFormula,
          specialCommercialTax, specialCommercialTaxFormula,
          commercialTax, commercialTaxFormula,
          rtadTax, rtadTaxFormula,
          totalCostInDollar,
          totalCostWithIncomeTaxInDollar,
          incomeTax } = result;

    let f = this.readableNumber;
    let fL = this.readableNumberInLakh;

    this.customDutyFieldTarget.value = f(customDuty);
    this.customDutyKyatFieldTarget.value = fL(customDuty, exchangeRate);
    this.customDutyFormulaFieldTarget.innerText = customDutyFormula;

    this.specialCommercialTaxFieldTarget.value = f(specialCommercialTax);
    this.specialCommercialTaxKyatFieldTarget.value = fL(specialCommercialTax, exchangeRate);
    this.specialCommercialTaxFormulaFieldTarget.innerText = specialCommercialTaxFormula,

    this.commercialTaxFieldTarget.value = f(commercialTax);
    this.commercialTaxKyatFieldTarget.value =  fL(commercialTax, exchangeRate);
    this.commercialTaxFormulaFieldTarget.innerText = commercialTaxFormula;

    this.rtadTaxFieldTarget.value = f(rtadTax);
    this.rtadTaxKyatFieldTarget.value = fL(rtadTax, exchangeRate);
    this.rtadTaxFormulaFieldTarget.innerText = rtadTaxFormula;

    this.costInDollarFieldTarget.value = f(totalCostInDollar);
    this.costInKyatFieldTarget.value = fL(totalCostInDollar, exchangeRate);

    if (this.checkIncomeTaxFieldTarget.checked) {
      this.totalCostInDollarFieldTarget.value = f(totalCostWithIncomeTaxInDollar);
      this.totalCostInKyatFieldTarget.value = fL(totalCostWithIncomeTaxInDollar, exchangeRate);
      this.incomeTaxFieldTarget.value = f(incomeTax);
      this.incomeTaxKyatFieldTarget.value = fL(incomeTax, exchangeRate);
    } else {
      this.totalCostInDollarFieldTarget.value = f(totalCostInDollar);
      this.totalCostInKyatFieldTarget.value = fL(totalCostInDollar, exchangeRate);
      this.incomeTaxKyatFieldTarget.value =  0;
      this.incomeTaxFieldTarget.value = 0;
    }
  }

  readableNumberInLakh(dollar, exchangeRate) {
    let lakh = dollar * exchangeRate / 100000
    return lakh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' သိန်း'
  }

  readableNumber(number) {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
}
