import { Controller } from 'stimulus'

export default class extends Controller {

  static values = {
    isVisitor: Boolean
  }

  remember(){
    if(this.isVisitorValue){
      document.cookie = `last_lead_generated_at=${(new Date()).getTime()}`
    }
  }
}
