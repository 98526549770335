import { findElement, getItemFromStore, setItemToStore, hasClass} from "../src/v3/utils";
import { Controller } from "stimulus";

const ClassNames = {
  NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed',
  NAVBAR_VERTICAL_COLLAPSED_HOVER: 'navbar-vertical-collapsed-hover',
};

const STORE_KEY = 'isNavbarVerticalCollapsed';

const StoreValue = {
  TRUE: 'yes',
  FALSE: 'no'
};

export default class extends Controller {
  static targets = [
    'toggle'
  ]

  connect(){
    this.html = findElement('html');

    const isNavbarVerticalCollapsed = getItemFromStore(STORE_KEY);
    if(isNavbarVerticalCollapsed == StoreValue.FALSE){
      this.html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED);
    }
  }

  toggle(e){
    this.html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED)
    const isNavbarVerticalCollapsed = getItemFromStore(STORE_KEY)
    setItemToStore(STORE_KEY, isNavbarVerticalCollapsed == StoreValue.TRUE ? StoreValue.FALSE : StoreValue.TRUE)
  }
  
  open(e){
    if(hasClass(this.html, ClassNames.NAVBAR_VERTICAL_COLLAPSED)){
      this.html.classList.add(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
    }
  }

  close(e){
    if(hasClass(this.html, ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER)){
      this.html.classList.remove(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
    }
  }
}