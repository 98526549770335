import { trackEvent } from 'src/v3/gtm_tracker';

const getTracker = (action) => {
  return (e) => {
    trackEvent({
      action: action,
      category: 'V3::Layout::Footer',
      label: e.currentTarget.href
    })
  }
}
const initLayoutFooterTracker = () => {
  const keywordLinkMakeTracker = getTracker('ClickKeywordLinkMake');
  Array.prototype.forEach.call(document.querySelectorAll('.keyword-link-make a'), (element) => {
    element.addEventListener('click', keywordLinkMakeTracker);
  });

  const keywordLinkModelTracker = getTracker('ClickKeywordLinkModel');
  Array.prototype.forEach.call(document.querySelectorAll('.keyword-link-model a'), (element) => {
    element.addEventListener('click', keywordLinkModelTracker);
  });

  const carsByLocationTracker = getTracker('ClickCarsByLocation');
  Array.prototype.forEach.call(document.querySelectorAll('.cars-by-locations a'), (element) => {
    element.addEventListener('click', carsByLocationTracker);
  });

  const brandNewCarsLinkTracker = getTracker('ClickBrandNewCarsLinks');
  Array.prototype.forEach.call(document.querySelectorAll('.brand-new-cars-links a'), (element) => {
    element.addEventListener('click', brandNewCarsLinkTracker);
  });

  const keywordDescriptionLinkTracker = getTracker('ClickKeywordDescriptionLink');
  Array.prototype.forEach.call(document.querySelectorAll('.keyword-description a'), (element) => {
    element.addEventListener('click', keywordDescriptionLinkTracker);
  });

  const footerLinkTracker = getTracker('ClickFooterLink');
  Array.prototype.forEach.call(document.querySelectorAll('footer a'), (element) => {
    element.addEventListener('click', footerLinkTracker);
  });
}

export default initLayoutFooterTracker;
