import { findElement } from "src/v3/utils";
import { get } from '@rails/request.js';

export const initBrandNewCompareModal = () => {
  let brandNewCompareModalEl = [].slice.call(document.querySelectorAll('.brand-new-comparison-modal'))

  if(!brandNewCompareModalEl){
    return;
  }
  brandNewCompareModalEl.map((brandNewEl) => {
    brandNewEl.addEventListener('click', onModalOpen);
  })
}

async function onModalOpen(e){
  let element = findElement('#comparison-tool-v3 .modal-content')
  let url = e.target.dataset.url;
  const response = await get(url)
  if(response.ok){
    let html = await response.text
    populateContent(element, html, false);
  }else{
    console.error(`Error getting ${url}`)
  }
}

export const brandNewModelEl = () => {
  let brandNewManufacturerElList = [].slice.call(document.querySelectorAll('.select_brand_new_manufacturer'));
  let brandTabEl = findElement('#brand-tab');
  let modelTabEl = findElement('#model-tab');
  if(!brandNewManufacturerElList){
    return;
  }

  brandNewManufacturerElList.map((brandNewManufacturerEl) => brandNewManufacturerEl.addEventListener('click', onManufacturerSelect))

  brandTabEl.addEventListener('click', (event) => {
    event.preventDefault();
    brandTabEl.classList.add('border-3', 'border-bottom', 'border-success', 'active');
    modelTabEl.classList.remove('border-3', 'border-bottom', 'border-success', 'active');
  });

  modelTabEl.addEventListener('click', (event) => {
    event.preventDefault();
    brandTabEl.classList.remove('border-3', 'border-bottom', 'border-success', 'active');
    modelTabEl.classList.add('border-3', 'border-bottom', 'border-success', 'active');
  });
}

async function onManufacturerSelect(event){
  event.preventDefault();
  let brandNewModelEl = findElement('#model');
  let brandNewManufacturerUrl = event.currentTarget.dataset.url;

  const response = await get(brandNewManufacturerUrl)
  if(response.ok){
    const html = await response.text
    populateContent(brandNewModelEl, html, true)
  }else{
    console.error(`Error getting ${brandNewManufacturerUrl}`)
  }
}

const populateContent = (element, html, flag) => {
  element.innerHTML = html;

  if (flag) {
    findElement('#brand').classList.remove('active');
    element.classList.add('active');
    toggleActiveTab();
    return true;
  } else {
    brandNewModelEl();
  }
}

const toggleActiveTab = () => {
  let brandTabToggleEl = findElement('#brand-tab');
  let modelTabToggleEl = findElement('#model-tab');
  brandTabToggleEl.classList.remove('border-3', 'border-bottom', 'border-success', 'active');
  modelTabToggleEl.classList.add('border-3', 'border-bottom', 'border-success', 'active');
}

export default initBrandNewCompareModal;
