import { Controller } from 'stimulus';
import { trackEvent } from 'src/v3/gtm_tracker';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'text'
  ];

  static values = {
    leadsPath: String,
    id: String
  }

  connect(){
    // nothing to do here.
  }

  async call(event){
    this.textTarget.textContent = event.currentTarget.href.replace(/tel:/, '');
    const id = this.idValue
    // record lead
    let response = await post(this.leadsPathValue, {
      reponseKind: 'json',
      body: {
        leadable_type: 'CarListing',
        leadable_id: id
      }
    });
    if(response.ok){
      console.log(`Lead recorded for CarListings#${id}`);
    }else{
      console.error(`Lead recording failed for CarListing#${id}`)
    }

    // trackEvent in gtm_tracker
    trackEvent({
      action: this.data.get('action') || 'CarListing::CallButtonController::Call',
      category: 'LeadGeneration',
      label: this.data.get('label')
    });
  }
}
