import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    document.getElementById('other-numbers')
      .lastElementChild
      .firstElementChild
      .classList
      .remove('is-valid')
  }

  add(e) {
    let btn = e.currentTarget
    let otherNumberEle = document.getElementById(btn.dataset['inputId'])
    let otherNumber = otherNumberEle.value
    let nextIndex = parseInt(otherNumberEle.dataset['number']) + 1
    let new_input = this.anotherOtherNumberInput(nextIndex)
    let parent = document.getElementById('other-numbers')
    console.log('edit_other_number_controller')
    if( otherNumber.match(/^((\+\d+)*(\d+)[- ])*\d{3,}[- ]*\d{3,}$/) ){
      otherNumberEle.classList.remove('is-invalid')
      otherNumberEle.classList.add('is-valid')
      this.buttonAddAndRemoveClasses(btn)
      parent.appendChild(new_input)
    }else{
      otherNumberEle.classList.remove('is-valid')
      otherNumberEle.classList.add('is-invalid')
      otherNumberEle.parentNode.appendChild(this.invalidNode())
    }
  }

  buttonAddAndRemoveClasses(btn){
    btn.removeAttribute('data-action')
    btn.setAttribute('data-action', 'click->edit-other-number#remove')
    btn.removeChild(btn.lastElementChild)
    btn.appendChild(this.minusSignTag())
  }

  remove(e) {
    document.getElementById("user-"+e.currentTarget.dataset['inputId']).remove()
  }

  minusSignTag(){
    var tag = document.createElement('i')
    tag.className = 'fa fa-minus'
    return tag
  }

  plusSignTag(){
    var tag = document.createElement('i')
    tag.className = 'fa fa-plus'
    return tag
  }

  invalidNode(){
    var invalid_node = document.createElement('div')
    invalid_node.className = 'invalid-feedback'
    invalid_node.innerHTML = 'Please add a valid phone number.'
    return invalid_node
  }

  anotherOtherNumberInput(index){
    var node = document.createElement('div')
    node.className = 'input-group user_other_number mb-3'
    node.id = 'user-other-number-'+ index

    var input = document.createElement('input')
    input.className = 'string optional form-control other-number'
    input.step = 'any'
    input.id = 'other-number-'+ index
    input.setAttribute('data-number', index)
    input.name - 'edit_profile_form[user_attributes][other_numbers][]'
    input.type = 'text'

    var span = document.createElement('span')
    span.class = 'input-group-btn'

    var btn = document.createElement('button')
    btn.className = 'btn btn-carsdb-default'
    btn.setAttribute('data-input-id', 'other-number-'+index)
    btn.type = 'button'
    btn.setAttribute('data-action', 'click->edit-other-number#add')

    node.appendChild(input)
    node.appendChild(span)
    span.appendChild(btn)
    btn.appendChild(this.plusSignTag())

    return node
  }
}
