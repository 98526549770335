import { Controller } from 'stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'continueBtn']

  async update(e) {
    let url = e.target.dataset.url
    this.continueBtnTarget.innerHTML = 'Loading …'
    let value = e.target.value == 'default' ? null : e.target.value
    const imageResponse = await patch(url, {
                                        body: { image: { category: value } },
                                        contentType: 'application/json'
                                      });
    if(imageResponse.ok){
      this.continueBtnTarget.innerHTML = 'Continue'
      console.log('Sucessfully updated image')
    }else{
      alert('Cannot update image, please delete the image and upload again!')
      console.error(`Error occured ordering image ${url}`)
    }
  }
}
