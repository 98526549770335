import { Controller } from 'stimulus'
import Glider from 'glider-js';

export default class extends Controller {

  static values = {
    setting: Object,
  }

  static targets = ['container']

  connect() {
    this.initGlide(this.containerTarget, this.settingValue)
  }

  initGlide(container, setting) {
    let { glider } = container.dataset;
    //do not create new glider again on turbo restoration visits
    //because they screw up the layout
    if(glider == 'complete'){ return }
    this.glider = new Glider(container, setting);
    container.dataset.glider = 'complete'
  }

  disconnect(){
    this.glider.destroy();
    this.containerTarget.dataset.glider = 'destroyed'
  }

  scrollTo(e) {
    let target = parseInt(e.currentTarget.dataset.value)
    this.glider.scrollItem(target)
  }
}
