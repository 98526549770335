import history from './custom_history';

// Private variables for keeping tracking of depenecies injected.
let defaultIndexNameArg = '';
let defaultTitleArg = 'Search Car Listings';
let localeArg = 'en';

// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
const getSlug = (name) => {
  return name
    .split(' ')
    .map(encodeURIComponent)
    .join('+');
}

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
const getName = (slug) => {
  return slug
    .split('+')
    .map(decodeURIComponent)
    .join(' ');
}

// Price range converter
const rangeToQueryParam = (range) => range.replace(':', '-');
const queryParamToRange = (queryParamValue) => queryParamValue.replace('-', ':');


const windowTitle = ({ manufacturer, models, q }) => {
  const text = {
    noSearchQuery: {
      en: defaultTitleArg,
      my: defaultTitleArg
    }
  }

  const queryTitle = q
    ? { en: `cars that match ‘${q}’ for sale`, my: `မှ ‘${q}’ နှင့်သက်ဆိုင်ရာများ`}
    : { en: `cars for sale`, my: '' };

  const modelsPart =  models
    ? models.join(', ')
    : false;

  const parts = [manufacturer, modelsPart].filter(Boolean);

  if(parts.length === 0 && !q){
    return text.noSearchQuery[localeArg];
  }else{
    return {
      en: `${parts.join(' ')} ${queryTitle[localeArg]}`,
      my: `${parts.join(' ')} ကားကြော်ငြာများ${queryTitle[localeArg]}`
    }[localeArg];
  }
}

export const createUrlHelper = (routeState) => {
  const manufacturerPath = routeState.manufacturer
      ? `${getSlug(routeState.manufacturer)}/`
      : '';

  const queryParameters = {};

  if (routeState.q) {
    queryParameters.q = encodeURIComponent(routeState.q);
  }
  if (routeState.page !== 1) {
    queryParameters.page = routeState.page;
  }
  if (routeState.models) {
    queryParameters.models = routeState.models;
  }
  if (routeState.price) {
    queryParameters.price = rangeToQueryParam(routeState.price);
  }
  if (routeState.year) {
    queryParameters.year = rangeToQueryParam(routeState.year);
  }
  if (routeState.mileage) {
    queryParameters.mileage = rangeToQueryParam(routeState.mileage);
  }
  if (routeState.buildType){
    queryParameters.buildType = routeState.buildType;
  }
  if (routeState.type){
    queryParameters.type = routeState.type;
  }
  if (routeState.location){
    queryParameters.location = routeState.location;
  }
  if (routeState.licenceStatus) {
    queryParameters.licenceStatus = routeState.licenceStatus;
  }
  if (routeState.fuelType) {
    queryParameters.fuelType = routeState.fuelType;
  }
  if (routeState.inspectors) {
    queryParameters.inspectors = routeState.inspectors;
  }
  if (routeState.sellerTypes) {
    queryParameters.sellerTypes = routeState.sellerTypes;
  }
  if (routeState.plateDivision) {
    queryParameters.plateDivision = routeState.plateDivision;
  }
  if (routeState.plateColor) {
    queryParameters.plateColor = routeState.plateColor;
  }
  if (routeState.transmission) {
    queryParameters.transmssion = routeState.transmission;
  }
  if (routeState.steeringPosition) {
    queryParameters.steeringPosition = routeState.steeringPosition;
  }
  if (routeState.color) {
    queryParameters.color = routeState.color;
  }
  if (routeState.dealer) {
    queryParameters.dealer = routeState.dealer;
  }
  if (routeState.condition) {
    queryParameters.condition = routeState.condition;
  }
  return { manufacturerPath, queryParameters };
};

const createURL = ({ qsModule, routeState, location }) => {
  const urlParts = location.href.match(/^(.*?)\/car-listings\/search\/?/);
  const baseUrl = `${urlParts ? urlParts[1] : ''}/`;

  const { manufacturerPath, queryParameters } = createUrlHelper(routeState);

  const queryString = qsModule.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat'
  });
  return `${baseUrl}car-listings/search/${manufacturerPath}${queryString}`;
}

const parseURL = ({ qsModule, location: locationArg }) => {
  const pathnameMatches = locationArg.pathname.match(/car-listings\/search\/(.*?)\/?$/);
  const manufacturer = getName(
    (pathnameMatches && pathnameMatches[1]) || ''
  );

  const {
    q = '',
    page,
    models = [],
    price,
    mileage,
    buildType,
    type,
    year,
    location,
    fuelType,
    licenceStatus,
    inspectors = [],
    sellerTypes = [],
    plateDivision,
    plateColor,
    transmission,
    steeringPosition,
    color,
    dealer,
    condition } = qsModule.parse(
    locationArg.search.slice(1)
  );
  // `qs` does not return an array when there's a single value.
  const allModels = Array.isArray(models)
    ? models
    : [models].filter(Boolean);

  const allInspectors = Array.isArray(inspectors)
    ? inspectors
    : [inspectors].filter(Boolean);

  const allSellerTypes = Array.isArray(sellerTypes)
    ? sellerTypes
    : [sellerTypes].filter(Boolean);

  const priceRange = (price && queryParamToRange(price)) || '';
  const yearRange = (year && queryParamToRange(year)) || '';
  const mileageRange = (mileage && queryParamToRange(mileage) || '')

  return {
    q: decodeURIComponent(q),
    models: allModels.map(decodeURIComponent),
    price: priceRange,
    year: yearRange,
    mileage: mileageRange,
    page,
    manufacturer,
    buildType,
    type,
    location,
    fuelType,
    licenceStatus,
    inspectors: allInspectors.map(decodeURIComponent),
    sellerTypes: allSellerTypes.map(decodeURIComponent),
    plateDivision,
    plateColor,
    transmission,
    steeringPosition,
    color,
    dealer,
    condition
  };
}

const stateToRoute = (uiState) => {
  const indexName = Object.keys(uiState)[0];
  const indexUiState = uiState[indexName] || {};

  return {
    q: indexUiState.query,
    page: indexUiState.page,
    manufacturer: indexUiState.menu && indexUiState.menu['manufacturer.name'],
    models: indexUiState.refinementList && indexUiState.refinementList['model.name'],
    price: indexUiState.range && indexUiState.range.price_in_lakh,
    year: indexUiState.range && indexUiState.range.year,
    mileage: indexUiState.range && indexUiState.range.mileage_in_kilo,
    buildType: indexUiState.menu && indexUiState.menu['build_type.name'],
    type: indexUiState.menu && indexUiState.menu.car_type,
    location: indexUiState.menu && indexUiState.menu['state.name'],
    fuelType: indexUiState.menu && indexUiState.menu['fuel_type.name'],
    licenceStatus: indexUiState.menu && indexUiState.menu['licence_status'],
    inspectors: indexUiState.refinementList && indexUiState.refinementList['inspector.name'],
    sellerTypes: indexUiState.refinementList && indexUiState.refinementList['user.seller_type'],
    plateDivision: indexUiState.menu && indexUiState.menu['plate_division'],
    plateColor: indexUiState.menu && indexUiState.menu['plate_color'],
    transmission: indexUiState.menu && indexUiState.menu['transmission.name'],
    steeringPosition: indexUiState.menu && indexUiState.menu['steering_position'],
    color: indexUiState.menu && indexUiState.menu['color.name'],
    dealer: indexUiState.menu && indexUiState.menu['user.username'],
    condition: indexUiState.menu && indexUiState.menu['condition']
  };
}

const routeToState = (routeState) => {
  let state = {};
  state[defaultIndexNameArg] = {
    query: routeState.q,
    page: routeState.page,
    menu: {
      'manufacturer.name': routeState.manufacturer,
      'build_type.name': routeState.buildType,
      'car_type': routeState.type,
      'state.name': routeState.location,
      'fuel_type.name' : routeState.fuelType,
      'licence_status': routeState.licenceStatus,
      'plate_division' : routeState.plateDivision,
      'plate_color' : routeState.plateColor,
      'transmission.name' : routeState.transmission,
      'steering_position' : routeState.steeringPosition,
      'color.name': routeState.color,
      'user.username': routeState.dealer,
      'condition': routeState.condition
    },
    refinementList: {
      'model.name': routeState.models,
      'inspector.name': routeState.inspectors,
      'user.seller_type': routeState.sellerTypes
    },
    range: {
      price_in_lakh: routeState.price,
      year: routeState.year,
      mileage_in_kilo: routeState.mileage
    }
  };
  return state;
}

const customRouting = (defaultIndexName, { defaultTitle, locale }) => {
  defaultIndexNameArg = defaultIndexName;
  defaultTitleArg = defaultTitle;
  localeArg = locale;
  return {
    router: history({
      windowTitle,
      createURL,
      parseURL
    }),
    stateMapping: {
      stateToRoute,
      routeToState
    }
  }
}

export default customRouting;
