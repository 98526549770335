import { Tooltip } from 'bootstrap';
import { findElement } from 'src/v3/utils';

/**
 * Tooltip is under bootstrap which is in application_v3.js pack while searches are rendered with
 * algolia_search.js pack.
 *
 * So we are dispatching an event from search pack, and handling the event from here to initialize Tooltip
 */

const initToolTips = (container) => {
  const tooltipTriggerList = [].slice.call(container.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.forEach((el) => new Tooltip(el));
};


const initCarListingHitsRenderedListener = () => {
  const hitsContainerEl = findElement('[data-car-listing-search-target="hits"]');
  if(!hitsContainerEl){
    return;
  }

  hitsContainerEl.addEventListener(
    'algolia.car_listings_hits.rendered',
    (event) => {
      initToolTips(event.target)
    }
  )
}

export default initCarListingHitsRenderedListener;
