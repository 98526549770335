import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {

  static values = {
    end: String
  }

  connect(){
    this.timeInterval = setInterval(this.update.bind(this), 1000)
  }

  update(){
    const t = this.getTimeRemaining(this.endValue);
    const days = t.days > 1 ? `${t.days} days` : (t.days > 0 ? `${t.days} day` : '')
    const hours = `0${t.hours}`.slice(-2)
    const minutes = `0${t.minutes}`.slice(-2)
    const seconds = `0${t.seconds}`.slice(-2)
    this.element.innerText = [days, `${hours}:${minutes}:${seconds}`].join(' ')

    if(t.total <= 0){
      clearInterval(this.timeInterval)
      this.onTimeUp();
    }
  }

  onTimeUp(){
    Turbo.visit(location.toString());
  }

  getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))
    
    return {
      total,
      days,
      hours,
      minutes,
      seconds
    }
  }

}