import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'modelsSelect',
    'otherModelWrapper',
    'plateNumberField',
    'plateFields',
    'isSatiCheck',
    'licenceStatusSelect',
    'submitButton'
  ];

  connect() {
    // trigger changed event for licenceStatusSelect so that the fields are correctly displayed
    // in the event of validation errors
    this.licenceStatusSelectTarget.dispatchEvent(new Event('change'));
    // modify the state of plate number field according to isSatiCheckTarget's state
    this.changePlateNumberField(this.isSatiCheckTarget.checked);
  }

  manufacturerChosen(e){
    let manufacturerId = e.currentTarget.value;
    if(manufacturerId === ''){
      this.hideOtherModel();
      this.disableModelSelect();
    }else{
      this.enableModelSelect();
      this.hideOtherModel();
    }
  }

  modelChosen(e) {
    if(e.currentTarget.value === ''){
      this.showOtherModel();
    }else{
      this.hideOtherModel();
    }
  }

  isSatiChanged(e){
    this.changePlateNumberField(e.currentTarget.checked);
    this.enableSubmitButton();
  }

  changePlateNumberField(isSati){
    if(isSati){
      this.plateNumberFieldTarget.value = 'SATI';
      this.plateNumberFieldTarget.readOnly = true;
      this.hidePlateFields();
    }else{
      if(this.plateNumberFieldTarget.value == 'SATI'){
        this.plateNumberFieldTarget.value = '';
        this.plateNumberFieldTarget.classList.remove('is-valid');
      }
      this.plateNumberFieldTarget.readOnly = false;
      this.showPlateFields();
    }
  }

  licenceStatusChanged(e){
    if(e.currentTarget.value == 'cif'){
      this.hidePlateFields();
    }else if (e.currentTarget.value == 'licence'){
      this.showPlateFields();
    }else if (e.currentTarget.value == 'no_slip'){
      this.showPlateFields();
      this.checkIsSati();
    }
  }

  onSubmit(e){
    if(this.isPlateNumberRequired() && this.hasPlateNumberError()){
      this.plateNumberFieldTarget.classList.remove('is-valid');
      this.plateNumberFieldTarget.classList.add('is-invalid');
      this.plateNumberFieldTarget.scrollIntoView();
      e.preventDefault();
    }
  }

  plateNumberChanged(e){
    e.currentTarget.classList.remove('is-invalid');
    e.currentTarget.value = e.currentTarget.value.toUpperCase();
    this.enableSubmitButton();
  }

  submitButtonPressed(e){
    e.preventDefault();
    // submit using dispatchEvent so that onSubmit gets called
    this.formTarget.dispatchEvent(new Event('submit'));
  }

  showOtherModel(){
    this.otherModelWrapperTarget.style.display = 'flex';
  }

  hideOtherModel(){
    this.otherModelWrapperTarget.style.display = 'none';
  }

  enableModelSelect(){
    this.modelsSelectTarget.disabled = false;
  }

  disableModelSelect(){
    this.modelsSelectTarget.disabled = true;
  }

  hidePlateFields(){
    this.plateFieldsTarget.style.display = 'none';
  }

  showPlateFields(){
    this.plateFieldsTarget.style.display = 'block';
  }

  checkIsSati(){
    if(!this.isSatiCheckTarget.checked){
      this.isSatiCheckTarget.click()
    }
  }

  isPlateNumberRequired(){
    return this.licenceStatusSelectTarget.value != '' &&
           this.licenceStatusSelectTarget.value != 'cif' &&
           !this.isSatiCheckTarget.checked;
  }

  hasPlateNumberError(){
    return !this.plateNumberFieldTarget.value.match(/^[1-9A-Z][A-Z]-[0-9]{4}$/)
  }

  enableSubmitButton(){
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.value = 'Create Car Listing';
  }
}
