import { Controller } from 'stimulus';
import calculate from 'src/v3/insurance/premium_calculator';
import { post } from '@rails/request.js';
import { trackEvent } from '../../src/v3/gtm_tracker';

export default class extends Controller {
  static values = {
    showResult: Boolean,
    action: String,
    label: String
  }

  static targets = [
    'calculateFormField',
    'proposerNameField',
    'phoneNumberField',
    'enginePowerField',
    'carPriceField',
    'actOfGodIncludedField',
    'theftIncludedField',
    'warRiskIncludedField',
    'insurancePremiumResultField',
    'basicPremiumField',
    'thirdPartyField',
    'windScreenField',
    'nilExcessField',
    'srccField',
    'showActOfGodCostField',
    'showWarRiskCostField',
    'showTheftCostField',
    'totalPremiumPriceField',
    'amiCalculateButtonField'
  ]

  connect() {
    this.actOfGodIncluded = true;
    this.theftIncluded = true;
    this.warRiskIncluded = true;
    this.basicPremiumCost = 0;
    if(this.showResultValue){
      this.calculateAndPopulateResult()
      this.insurancePremiumResultFieldTarget.classList.remove('d-none');
    }
  }

  calculate(e){
    e.preventDefault();
    let url = e.target.action;
    trackEvent({
      category: 'LeadGeneration',
      action: this.actionValue,
      label: new URL(window.location.href).pathname
    })
    this.sendAmiQuotationRequest(url);
  }

  async sendAmiQuotationRequest(url) {
    let data = { 
      ami_quote_request: {
        proposer : this.proposerNameFieldTarget.value,
        phone_number : this.phoneNumberFieldTarget.value,
        car_value : this.carPriceFieldTarget.value,
        engine_power: this.enginePowerFieldTarget.value,
        aog_included: this.actOfGodIncludedFieldTarget.value,
        theft_included: this.theftIncludedFieldTarget.value,
        wr_included: this.warRiskIncludedFieldTarget.value
      }
    };

    const response = await post(url, { body: data, responseKind: 'json' })
    if(response.ok){
      const responseBody = await response.json
      if(responseBody.success){
        this.calculateFormFieldTarget.classList.add('d-none')
        if(!this.showResultValue){
          this.calculateAndPopulateResult()
          this.insurancePremiumResultFieldTarget.classList.remove('d-none')
        }
      }else{
        this.resetSubmitButton();
      }
    }else{
      this.resetSubmitButton();
      return true;
    }
  }

  toggleActOfGod(event) {
    this.actOfGodIncluded = event.currentTarget.checked;
    this.calculateAndPopulateResult();
  }

  toggleWarRisk(event) {
    this.warRiskIncluded = event.currentTarget.checked;
    this.calculateAndPopulateResult();
  }

  toggleTheftField(event) {
    this.theftIncluded = event.currentTarget.checked;
    this.calculateAndPopulateResult();
  }

  resetSubmitButton(){
    this.amiCalculateButtonFieldTarget.removeAttribute('disabled');
    this.amiCalculateButtonFieldTarget.innerText = 'Submit'
  }
  
  calculateAndPopulateResult() {
    let { result, total } = calculate(
      this.enginePowerFieldTarget.value,
      this.carPriceFieldTarget.value,
      {
        warRiskIncluded: this.warRiskIncluded,
        actOfGodIncluded: this.actOfGodIncluded,
        theftIncluded: this.theftIncluded
      }
    );

    const f = (num) => num.toLocaleString(undefined, { style: 'currency', currency: 'MMK' });

    this.nilExcessFieldTarget.innerText = f(result.nilExcess);
    this.basicPremiumFieldTarget.innerText = f(result.basicPremiumCost);
    this.thirdPartyFieldTarget.innerText = f(result.thirdPartyCost);
    this.windScreenFieldTarget.innerText = f(result.amiWindScreenCost);
    this.srccFieldTarget.innerText = f(result.srccCost);
    this.showWarRiskCostFieldTarget.innerText = f(result.warRiskIncludedCost);
    this.showActOfGodCostFieldTarget.innerText = f(result.actOfGodIncludedCost);
    this.showTheftCostFieldTarget.innerText = f(result.theftIncludedCost);
    this.totalPremiumPriceFieldTarget.innerText = f(total);
  }
}
