import { Controller } from 'stimulus';
import { algoliaAppID, algoliaSearchApiKey } from 'src/constants';
import algoliasearch from 'algoliasearch';
import { trackEvent } from 'src/v3/gtm_tracker';
import { Turbo } from '@hotwired/turbo-rails';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

export default class extends Controller {
  static targets = [
    'manuField',
    'modelField',
    'searchInput',
    'autocomplete'
  ]

  static values = {
    environment: String
  }

  connect() {
    this.initAutoComplete();
  }

  disconnect(){
    this.autocompleteTarget.innerHTML = '';
  }

  initAutoComplete() {
    const searchClient = algoliasearch(algoliaAppID, algoliaSearchApiKey);
    const environment = this.environmentValue
    const controller = this
    const autocompleteChoice = this.autocompleteChoice;

    const onSelectPlugin = () => {
      return {
        subscribe({ onSelect }){
          onSelect(autocompleteChoice.bind(controller))
        }
      }
    }
    const { setQuery } = autocomplete({
      container: this.autocompleteTarget,
      placeholder: 'Enter make or model',
      getSources({ query }){
        return [{
          sourceId: 'brand_new',
          getItems(){
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: `BrandNew_${environment}`,
                  query: query,
                  params: {
                    hitsPerPage: 5
                  }
                }
              ]
            })
          },
          templates: {
            item({ item }){
              return `${item.title}`
            }
          }
        }]
      },
      plugins: [onSelectPlugin()],
    })
    this.setAutocompleteQuery = setQuery;
  }

  autocompleteChoice({ item }){
    this.setAutocompleteQuery(item.title)
    if (item.type === "CarModel") {
      this.manuFieldTarget.value = item.manufacturer.slug
      this.modelFieldTarget.value = item.slug
    } else {
      this.manuFieldTarget.value = item.slug
      this.modelFieldTarget.value = ''
    }
    this.redirect();
  }

  redirect(){
    let path = this.getPath();
    let brandnewPath = this.element.action;
    trackEvent({
      action: 'SearchBrandNewCars',
      category: 'V3::Home::TopBanner',
      label: path
    });
    Turbo.visit([brandnewPath, path].join('/'))
  }

  getPath() {
    return this.manuFieldTarget.value + '/' + this.modelFieldTarget.value;
  }
}
