import { Controller } from 'stimulus'
import * as LoanCalculator from 'src/v3/car_listing/loan_calculator'

export default class extends Controller {
  static targets = [
    'downPaymentPercentField',
    'durationField',
    'downPaymentValueField',
    'monthlyPaymentValueField'
  ]

  connect() {
    this.calculate();
  }

  calculate() {
    let carPrice = this.data.get('car-price');
    let selectedDownPaymentPercent = 0;
    let selectedDownPaymentElement = this.downPaymentPercentFieldTarget;
    if(selectedDownPaymentElement) {
      selectedDownPaymentPercent = selectedDownPaymentElement.value;
    }
    LoanCalculator.init(carPrice, this.durationFieldTarget.value, selectedDownPaymentPercent);
    this.downPaymentValueFieldTarget.innerHTML = LoanCalculator.initial_payment();
    this.monthlyPaymentValueFieldTarget.innerHTML = LoanCalculator.monthly_payment();
    document.querySelector('.financing-available-text').innerHTML = this.monthlyPaymentValueFieldTarget.innerHTML + ' Lakhs/month'
  }

  change(){
    this.calculate();
  }
}
