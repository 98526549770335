import { Controller } from 'stimulus'
import { post } from '@rails/request.js'
import { trackEvent } from '../src/v3/gtm_tracker'

export default class extends Controller{
  static values = {
    copy: String,
    leadPath: String
  }

  async copyAndTrackLead(e){
    this.copy(this.copyValue)

    const leadResponse = await post(this.leadPathValue)
    if(leadResponse.ok){
      console.log('Recorded lead')
    }else{
      console.error(`Error recording lead ${url}`)
    }

    trackEvent({
      action: 'CarListings::Show::CallContactNumber',
      category: 'LeadGeneration',
      label: window.location.pathname
    });
  }

  copy(str){
    var data = [new ClipboardItem({ "text/plain": new Blob([str], { type: "text/plain" }) })]
    navigator.clipboard.write(data).then(
      (e) => {
        this.element.innerHTML = `<i class="far fa-copy"></i>
          Copied
          <span class="d-none d-md-inline"> to clipboard</span>!`
        console.log('Copied to clipboard')
      },
      (e) => console.error('There is an error in copying to clipboard')
    )
  }
}
