// aa is short for algolia analytics

import { Controller } from 'stimulus'
import aa from 'search-insights';
import { algoliaAppID, algoliaSearchApiKey } from 'src/constants';

export default class extends Controller{

  static values = {
    userToken: String,
    name: String,
    id: String
  }

  connect(){
    aa('init', { appId: algoliaAppID, apiKey: algoliaSearchApiKey});
  }

  sendEvent(e){
    const searchParams = new URL(window.location.href).searchParams
    const queryID = searchParams.get('qid'),
          position = searchParams.get('p'),
          index = searchParams.get('i');

    if(queryID && position && index){
      aa('convertedObjectIDsAfterSearch', {
        userToken: this.userTokenValue,
        index,
        eventName: this.nameValue,
        queryID,
        objectIDs: [this.idValue]
      })
    }
  }
}