import { Modal } from "bootstrap";
import { findElement } from "src/v3/utils";

const showCreditNotEnoughModal = () => {
  let showCreditNotEnoughModal = findElement('#credit-not-enough');
  if (!showCreditNotEnoughModal){
    return;
  }

  new Modal(showCreditNotEnoughModal).show();
};

export default showCreditNotEnoughModal;