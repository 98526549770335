import { Controller } from 'stimulus';
import { algoliaAppID, algoliaSearchApiKey } from 'src/constants';
import algoliasearch from 'algoliasearch';
import { trackEvent } from 'src/v3/gtm_tracker';
import { createUrlHelper } from 'src/v3/algolia/custom_routing'
import qsModule from 'query-string'; // part of nodejs core
import { Turbo } from '@hotwired/turbo-rails';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

export default class extends Controller {
  static values = {
    environment: String
  }

  static targets = [ 'autocomplete', 'modelField', 'manufacturerField', 'searchInputField' ]


  connect() {
    this.initAutocomplete();
  }

  disconnect(){
    this.autocompleteTarget.innerHTML = '';
  }

  initAutocomplete(){
    const searchClient = algoliasearch(algoliaAppID, algoliaSearchApiKey);
    const environment = this.environmentValue;
    const autocompleteChoice = this.autocompleteChoice;
    const controller = this;
    const searchInputFieldTarget = this.searchInputFieldTarget;

    const onSelectPlugin = () => {
      return {
        subscribe({ onSelect }){
          onSelect(autocompleteChoice.bind(controller))
        }
      }
    }

    const { setQuery } = autocomplete({
      container: this.autocompleteTarget,
      placeholder: 'Enter make, model or lot number',
      detachedMediaQuery: 'none',
      getSources({ query }) {
        return [{
          sourceId: 'car_model',
          getItems(){
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: `CarModel_${environment}`,
                  query: query,
                  params: {
                    hitsPerPage: 5
                  }
                }
              ]
            })
          },
          templates: {
            item({ item }){
              return `${item.full_name} (${item.count})`
            },
          }
        }];
      },
      plugins: [onSelectPlugin()],
      onSubmit({ event, state }){
        searchInputFieldTarget.value = state.query;
        controller.redirect(event);
      }
    });
    this.setAutocompleteQuery = setQuery;
  }

  autocompleteChoice({ item }){
    this.setAutocompleteQuery(item.full_name)
    this.searchInputFieldTarget.value = item.full_name
    this.modelFieldTarget.value = item.name
    this.manufacturerFieldTarget.value = item.manufacturer.name
  }

  redirect(e){
    e.preventDefault();
    let searchLocation = this.buildUrl(this.element);
    let carPath = this.element.action;
    const destination = `${carPath}/${searchLocation}`;
    trackEvent({
      action: 'SearchCarListings',
      category: 'V3::Home::TopBanner',
      label: destination
    });

    Turbo.visit(destination);
  }



  buildUrl(form) {
    const { query, model, manufacturer, year, price_min, price_max } = Object.fromEntries(new FormData(form));

    // if query given is essentially the same as `manufacturer model` then drop it
    const q = query == `${manufacturer} ${model}` ? '' : query;

    // rebuild the form data into the format that the helper expects and pass it
    const { manufacturerPath, queryParameters } = createUrlHelper({
      q,
      manufacturer,
      year: (year ? `${year}:` : ''),
      models: model ? [model] : undefined,
      price: (price_min || price_max) ? `${[price_min, price_max].join(':')}` : '',
      page: 1
    });

    const queryString = qsModule.stringify(queryParameters);

    return `${manufacturerPath}${queryString ? `?${queryString}` : ''}`;
  }
}
