import { Controller } from "stimulus";
import { get } from '@rails/request.js';

export default class extends Controller{
  static values = {
    url: String,
    id: String
  }

  change(e){
    const selectedValue = e.currentTarget.selectedOptions[0].value
    let url = new URL(this.urlValue.replace('placeholder', selectedValue));
    url.searchParams.append('id', this.idValue);
    get(url.toString(), { responseKind: 'turbo-stream' })
  }
}
