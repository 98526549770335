import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'chartArea'
  ]

  static values = {
    stats: String
  }

  connect(){
    this.drawChart();
  }

  drawChart() {
    google.charts.load("current", {packages:["corechart"]});
    google.charts.setOnLoadCallback(this.drawStatsChart)
  }

  drawStatsChart = () => {
    let statsDataObj = JSON.parse(this.statsValue)
    const statsDataArray = [["Date", "Impressions", "Leads", "Saved Cars"]];
    statsDataObj.forEach(function(item,i) {
      statsDataArray.push([
        item.date,
        item.stats.impressions,
        item.stats.leads,
        item.stats.saved_cars
      ])
    });

    var data = google.visualization.arrayToDataTable(statsDataArray);

    var options = {
      legend: { position: 'bottom' },
      vAxis: { minValue: '0' },
      hAxis: { slantedText: true },
      chartArea: { top: 28, height: '100%' },
      series: {
        0: { color: "#5ec2b1", pointSize: 5 },
        1: { color: "#fab940", pointSize: 5 },
        2: { color: "#64B5F6", pointSize: 5 }
      }
    };
    var chart = new google.visualization.LineChart(this.chartAreaTarget);
    return chart.draw(data, options);
  }
}
