import { Controller } from "stimulus"
import { get, patch } from '@rails/request.js';

const DELAY = 3000;

export default class extends Controller {
  static values = {
    url: String,
    readUrl: String
  }
  static targets = [
    'notificationContent',
    'emptyNotification',
    'count',
    'dropDownNotificationNavbar',
    'dropdownNotificationMenu',
    'markAllNotificationsRead'
  ]

  connect() {
    this.isBusy = false
    this.isLoaded = false
    this.toggleRedDot();
  }

  onOpen(e){
    e.preventDefault();
    this.toggleNotification();
    if(!this.isLoaded) this.getNotification();
  }

  async getNotification() {
    if(this.isBusy) return

    let url = this.urlValue;

    if(this.nextPage){
      url = url + `?page=${this.nextPage}`
    }

    this.isBusy = true
    const response = await get(url)
    if(response.ok){
      const body = await response.text
      this.populateContent(this.notificationContentTarget, body);
      this.toggleRedDot();
      let notification_length = this.notificationContentTarget
                                    .querySelectorAll('.notification-content .list-group-item')
                                    .length;
      if(notification_length > 0) {
        this.emptyNotificationTarget.classList.add('d-none');
      } else {
        this.notificationContentTarget.classList.add('d-none');
        this.emptyNotificationTarget.classList.remove('d-none');
      }

      this.isBusy = false
      this.isLoaded = true
    }
  }

  onScroll(event) {
    var target = event.target;
    let scrolled_percent = ((target.scrollTop + this.notificationContentTarget.clientHeight) / target.scrollHeight);
    if( scrolled_percent >= 0.9 && this.hasNextPage()){
      this.getNotification();
    }
  }

  toggleNotification() {
    let element =  this.dropdownNotificationMenuTarget.classList;
    if (element.contains('show')) {
      element.remove('show');
    } else {
      element.add('show');
      this.hideRedDot();
    }
  }

  toggleRedDot() {
    if (this.hasCountTarget) {
      let count = parseInt(this.countTarget.innerText);
      if(count > 0) {
        this.showRedDot();
      } else {
        this.hideRedDot();
      }
    }
  }

  showRedDot() {
    this.dropDownNotificationNavbarTarget.classList.add('notification-indicator', 'notification-indicator-warning');
  }

  hideRedDot() {
    this.dropDownNotificationNavbarTarget.classList.remove('notification-indicator', 'notification-indicator-warning');
  }

  populateContent(element, html) {
    element.innerHTML += html;
  }

  async markAllNotificationsAsRead(event) {
    event.stopPropagation();
    event.preventDefault();
    const response = await patch(this.markAllNotificationsReadTarget.href);
    if(response.ok){
      this.countTarget.classList.add('d-none');
      let elements = this.notificationContentTarget.querySelectorAll('.notification-unread');
      [].forEach.call(elements, (elem) => { elem.classList.remove("notification-unread"); });
    }
    return true;
  }

  markAsReadNotification(event) {
    event.stopPropagation();

    let element = event.currentTarget;
    if(!element.classList.contains('notification-unread')) return

    element.classList.remove('notification-unread')
    let url = this.readUrlValue.replace('--id--', element.dataset.id)
    patch(url)
  }

  hasNextPage(){
    let { value, nextPage } = this.notificationContentTarget
                                  .querySelector('.has_next_page:last-child')
                                  .dataset

    this.nextPage = nextPage;
    return value == 'true'
  }
}