import { Controller } from 'stimulus';
import { algoliaAppID, algoliaSearchApiKey } from 'src/constants';
import algoliasearch from 'algoliasearch/lite';
import customPagination from 'src/v3/algolia/custom_pagination';
import { getCarHits } from 'src/v3/algolia/car_hits';
import customRouting from 'src/v3/algolia/custom_routing';
import { breakpoints } from 'src/v3/utils';
import { trackEvent } from 'src/v3/gtm_tracker';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = {
    environment: String,
    locale: String,
    title: String,
    modal: String,
    refinements: Object,
    assets: Object,
    paths: Object,
    userId: String,
    savedCars: Array,
    isShowingContactNumber: Boolean,
    isVisitor: Boolean
  }

  static targets = [
    'title',
    'searchbox',
    'sortBy',
    'hits',
    'stats',
    'currentRefinements',
    'clearRefinements',
    'pagination'
  ]

  static Indices = {
    default: "CarListing_by_top_spot",
    dateNewest: "CarListing_by_date_desc",
    dateOldest: "CarListing_by_date_asc",
    priceLowest: "CarListing_by_price_asc",
    priceHighest: 'CarListing_by_price_desc',
    yearNewest: 'CarListing_by_year_desc',
    yearOldest: 'CarListing_by_year_asc',
    mileageLowest: 'CarListing_by_mileage_asc',
    mileageHighest: 'CarListing_by_mileage_desc'
  }

  async importDependencies(){
    let instantsearch = await import('instantsearch.js');
    this.instantsearch = instantsearch.default;

    let {
      searchBox,
      refinementList,
      rangeInput,
      menu,
      stats,
      sortBy,
      currentRefinements,
      clearRefinements,
      analytics,
      configure } = await import('instantsearch.js/es/widgets');

    this.widgets = {
      searchBox,
      refinementList,
      rangeInput,
      menu,
      stats,
      sortBy,
      currentRefinements,
      clearRefinements,
      analytics,
      configure
    }

    let aaModule = await import('search-insights');
    this.aa = aaModule.default;

    let { createInsightsMiddleware } = await import('instantsearch.js/es/middlewares/createInsightsMiddleware');
    this.createInsightsMiddleware = createInsightsMiddleware;
  }

  async connect(){
    await this.importDependencies();

    if(window.innerWidth < breakpoints.lg){
      this.initModal();
    }
    this.initTitleChangeObserver();
    // this.initHitsRenderedListener();
    this.initInstantSearch();
  }

  disconnect(){
    this.search.dispose();
  }

  index(key){
    return [
      this.constructor.Indices[key],
      this.environmentValue
    ].join('_')
  }

  initModal(){
    const modalEl = document.querySelector(this.modalValue);
    modalEl.addEventListener('shown.bs.modal', (e) => {
      this.modalShown = true;
    });
    modalEl.addEventListener('hidden.bs.modal', (e) => {
      this.modalShown = false;
    });
  }

  closeModal(){
    const modalEl = document.querySelector(this.modalValue);
    modalEl.querySelector('.btn-close').dispatchEvent(new MouseEvent('click'));
  }

  // This will observe the title tag and reflect the text of window title to h1
  initTitleChangeObserver(){
    const titleNode = this.titleTarget;
    new MutationObserver(function(mutations) {
      titleNode.textContent = mutations[0].target.text;
    }).observe(document.querySelector('title'), { subtree: true, characterData: true, childList: true });
  }

  initHitsRenderedListener(){
    this.hitsTarget.addEventListener(
      'algolia.car_hits.rendered',
      (event) => {
        // display google ad slots.
        googletag.cmd.push(function() {
          // googletag.display('div-gpt-ad-1600889834711-0');
          // googletag.display('div-gpt-ad-1600891189790-0');
          // googletag.pubads().refresh();
        });
      }
    )
  }

  initInstantSearch(){
    const searchClient = algoliasearch(algoliaAppID, algoliaSearchApiKey);
    this.aa('init', { appId: algoliaAppID, apiKey: algoliaSearchApiKey});
    this.aa('setUserToken', this.userIdValue);
    const insightsMiddleware = this.createInsightsMiddleware({
      insightsClient: this.aa,
    });
    const controller = this;

    this.search = this.instantsearch({
      indexName: this.index('default'),
      searchClient,
      routing: customRouting(
        this.index('default'),
        {
          defaultTitle: this.titleValue,
          locale: this.localeValue
        }
      ),
      searchFunction(helper) {
        if(controller.modalShown){
          controller.closeModal();
        }
        helper.search();
      }
    });
    this.search.addWidgets(this.buildWidgets());
    this.search.use(insightsMiddleware);
    this.search.start();
  }

  buildWidgets(){
    let widgets = [
      this.configure(),
      this.searchBox(),
      this.sortBy(),
      this.hits(),
      this.stats(),
      this.currentRefinements(),
      this.clearRefinements(),
      this.pagination(),
      this.analytics()
    ];
    const ids = this.refinementsValue.ids
    const idToWidgetMap = {
      'car-type': this.carTypeMenu.bind(this),
      'manufacturer': this.manufacturerMenu.bind(this),
      'model': this.modelRefinement.bind(this),
      'price-in-lakh': this.priceInLakhRangeInput.bind(this),
      'build-type': this.buildTypeMenu.bind(this),
      'trusted-cars': this.trustedCarsRefinement.bind(this),
      'seller-type': this.sellerTypeRefinement.bind(this),
      'year': this.yearRangeInput.bind(this),
      'licence-status': this.licenceStatusMenu.bind(this),
      'plate-division': this.plateDivisionMenu.bind(this),
      'plate-color': this.plateColorMenu.bind(this),
      'transmission': this.transmissionMenu.bind(this),
      'steering-position': this.steeringPositionMenu.bind(this),
      'mileage': this.mileageRangeInput.bind(this),
      'fuel-type': this.fuelTypeMenu.bind(this),
      'color': this.colorMenu.bind(this),
      'state': this.stateMenu.bind(this),
      'dealer': this.dealerMenu.bind(this),
      'condition': this.conditionMenu.bind(this)
    }

    ids.forEach((id) => {
      let widgetFn = idToWidgetMap[id];
      if(widgetFn){
        widgets.push(widgetFn(`#${id}`));
      }else{
        console.error(`Missing widget function for refinement id #${id}`);
      }
    });

    return widgets;
  }

  configure(){
    return this.widgets.configure({
      hitsPerPage: 10
    });
  }

  searchBox(){
    let timerId;

    return this.widgets.searchBox({
      container: this.searchboxTarget,
      placeholder: 'Search by Manufacturer, Model or Lot Number',
      autofocus: true,
      showReset: false,
      showSubmit: false,
      queryHook(query, refine){
        // debouncing https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/js/#debouncing
        clearTimeout(timerId);
        timerId = setTimeout(() => refine(query), 500);
      }
    });
  }

  sortBy(){
    return this.widgets.sortBy({
      container: this.sortByTarget,
      items: [
        { label: 'Default Sorting', value: this.index('default') },
        { label: 'Date (Newest first)', value: this.index('dateNewest') },
        { label: 'Date (Oldest first)', value: this.index('dateOldest') },
        { label: 'Price (Lowest first)', value: this.index('priceLowest') },
        { label: 'Price (Highest first)', value: this.index('priceHighest') },
        { label: 'Year (Newest first)', value: this.index('yearNewest') },
        { label: 'Year (Oldest first)', value: this.index('yearOldest') },
        { label: 'Mileage (Lowest first)', value: this.index('mileageLowest') },
        { label: 'Mileage (Highest first)', value: this.index('mileageHighest') }
      ]
    })
  }

  hits() {
    const carHits = getCarHits({
      assets: this.assetsValue,
      paths: this.pathsValue,
      isSaved: this.isSaved.bind(this),
      showContactNumber: this.isShowingContactNumberValue,
      isVisitor: this.isVisitorValue
    });
    return carHits({
      container: this.hitsTarget
    });
  }

  stats(){
    const textTemplate = {
      en: `
          {{#hasNoResults}}No car listing{{/hasNoResults}}
          {{#hasOneResult}}One car listing{{/hasOneResult}}
          {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} car listings{{/hasManyResults}}
          found <small class='text-muted'>in {{processingTimeMS}}ms</small>
        `,
      my: `
      ကားကြော်ငြာများ
      {{#hasNoResults}}တစ်စီးမှ ရှာမတွေ့ပါ{{/hasNoResults}}
      {{#hasOneResult}}တစ်စီး ရှာတွေ့ပါသည်{{/hasOneResult}}
      {{#hasManyResults}}{{#helpers.formatNumber}}{{nbHits}}{{/helpers.formatNumber}} စီး ရှာတွေ့ပါသည်{{/hasManyResults}}
      <small class='text-muted'>ရှာဖွေချိန် {{processingTimeMS}}ms ကြာမြင့်ပါသည်</small>
      `
    }
    return this.widgets.stats({
      container: this.statsTarget,
      templates: {
        text: textTemplate[this.localeValue],
      },
    })
  }

  currentRefinements(){
    const humanize = (attribute) => {
      let parts = attribute.split('.');
      let index = parts[1] == 'seller_type' ? 1 : 0;
      let part = parts[index].replace(/_/g, ' ');

      let splitStr = part.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }

    return this.widgets.currentRefinements({
      container: this.currentRefinementsTarget,
      transformItems: (items) => {
        return items.map((item) => {
          item.label = humanize(item.attribute)
          return item;
        });
      }
    });
  }

  clearRefinements(){
    return this.widgets.clearRefinements({
      container: this.clearRefinementsTarget,
      templates: {
        resetLabel: 'Clear filters'
      }
    })
  }

  pagination(){
    return customPagination({
      container: this.paginationTarget,
      scrollToNode: this.hitsTarget
    });
  }

  sellerTypeRefinement(id){
    return this.widgets.refinementList({
      container: id,
      attribute: 'user.seller_type'
    });
  }

  trustedCarsRefinement(id){
    return this.widgets.refinementList({
      container: id,
      attribute: 'inspector.name'
    });
  }

  carTypeMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'car_type'
    });
  }

  manufacturerMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'manufacturer.name'
   });
  }

  modelRefinement(id){
    return this.widgets.refinementList({
      container: id,
      attribute: 'model.name',
      searchable: true
    });
  }

  priceInLakhRangeInput(id){
    return this.widgets.rangeInput({
      container: id,
      attribute: 'price_in_lakh'
    });
  }

  buildTypeMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'build_type.name'
    });
  }

  yearRangeInput(id){
    return this.widgets.rangeInput({
      container: id,
      attribute: 'year'
    });
  }

  licenceStatusMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'licence_status'
    });
  }

  plateDivisionMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'plate_division'
    });
  }

  plateColorMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'plate_color'
    });
  }

  transmissionMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'transmission.name'
    });
  }

  steeringPositionMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'steering_position'
    });
  }

  mileageRangeInput(id){
    return this.widgets.rangeInput({
      container: id,
      attribute: 'mileage_in_kilo'
    });
  }

  fuelTypeMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'fuel_type.name'
    });
  }

  colorMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'color.name'
    });
  }

  stateMenu(id){
    return this.widgets.menu({
      container: '#state',
      attribute: 'state.name'
    })
  }

  dealerMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'user.username'
    })
  }

  conditionMenu(id){
    return this.widgets.menu({
      container: id,
      attribute: 'condition'
    })
  }

  analytics(){
    return this.widgets.analytics({
      pushFunction(formattedParameters, state, results) {
        trackEvent({
          action: 'RefineSearch',
          category: 'V3::CarListing::Search',
          label: formattedParameters
        });
      },
    })
  }

  save(e){
    e.preventDefault();
    let url =  e.currentTarget.href;
    let current_path = window.location.pathname;
    let manu = current_path.split('/')[3]
    let query = window.location.search.substring(1);
    let dest = `${url}?filters=${encodeURIComponent(query)}`
    if(current_path.includes('/my/') || current_path.includes('/en/')){
      manu = current_path.split('/')[4]
    }
    if(manu != undefined && manu != 'search' && manu != ''){
      var manu_query = `manufacturer=${manu}&`
      dest = `${url}?filters=${encodeURIComponent(manu_query)}${encodeURIComponent(query)}`
    }
    Turbo.visit(dest)
  }

  updateSavedCars(e){
    if(e.detail.action == 'Add'){
      this.savedCarsValue = [...this.savedCarsValue, e.detail.car_id]
    }else if(e.detail.action == 'Remove'){
      this.savedCarsValue = this.savedCarsValue.filter(id => id != e.detail.car_id)
    }
  }

  isSaved(id) {
    return this.savedCarsValue.includes(parseInt(id))
  }

}
