/**
 * Utils
 */

export const turboLoad = (listener) => {
  document.addEventListener("turbo:load", listener);

  // see if DOM is already available
  // if (document.readyState === 'loading') {
  //   document.addEventListener('DOMContentLoaded', listener);
  // } else {
  //   setTimeout(listener, 1);
  // }
};

export const initScrollToTopOnTurboSubmitFailed = () => {
  document.addEventListener('turbo:submit-end', (e) => {
    !e.detail.success && document.getElementById('top').scrollIntoView();
  });
}

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540,
};

export const hasClass = (el, className) => {
  !el && false;
  return el.classList.value.includes(className);
};

export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, payload);

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const formatNumber = (num) => {
  return num.toLocaleString();
}

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}