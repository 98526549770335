import { Controller } from "stimulus";
import { insertAfter, findElement } from '../../src/v3/utils'

export default class extends Controller{
  add(e){
    e.preventDefault()
    const btnElement = e.currentTarget
    btnElement.classList.add('d-none');

    const fields = btnElement.parentElement.querySelector('template').content.cloneNode(true);
    insertAfter(fields, btnElement);
  }

  remove(e){
    e.preventDefault();

    let fields = findElement(e.currentTarget.dataset.target);
    findElement(fields.parentNode, '.btn-add-fields').classList.remove('d-none');
    fields.remove();
  }
}
